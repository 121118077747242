import clsx from 'clsx'
import { makeStyles, Chip, ChipProps } from '@material-ui/core'
import { User } from 'app/entities/types'
import { userRoleIs } from 'app/entities/methods'
import { Color } from 'theme/style'
import { UserRoleValue } from 'app/values'

const useStyles = makeStyles((theme) => ({
  root: {
    transition: 'none'
  },
  tagDev: {
    backgroundColor: Color.TagUserRoleDevBackground,
    color: Color.TagUserRoleDevText
  },
  tagAdmin: {
    backgroundColor: Color.TagUserRoleAdminBackground,
    color: Color.TagUserRoleAdminText
  },
}))

interface UserRoleTagProps extends ChipProps {
  className?: string
  user: User
}

const UserRoleTag = ({ ...props }: UserRoleTagProps) => {
  const classes = useStyles()

  let tagClass = ''
  if (userRoleIs(props.user.role, UserRoleValue.Dev)) tagClass = classes.tagDev
  if (userRoleIs(props.user.role, UserRoleValue.Admin)) tagClass = classes.tagAdmin

  return (
    <Chip {...props} className={clsx(classes.root, props.className, tagClass)} />
  )
}

export default UserRoleTag
