
const style = {
  root: {
    minHeight: 'auto',
    height: '100%',
    overflow: 'visible !important'
  },
  indicator: {
    display: 'none'
  },
  scroller: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'visible !important'
  }
}

export default style