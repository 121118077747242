import { Dialog, DialogProps } from 'components'
import { useLocalization } from 'components/methods'

export type ExportDialogFieldProps = {
  name: string
  onUpdate?: (name: string, value: any) => void
}

interface ExportDialogProps extends DialogProps {
  exportParameters?: { [key: string]: any }
}

const ExportDialog = ({ ...props }: ExportDialogProps) => {
  const { t } = useLocalization()

  return (
    <Dialog open={props.open}
      title={props.title}
      description={props.description}
      content={props.content}
      confirmText={t('common.export')}
      confirmButtonCategory={props.confirmButtonCategory}
      onConfirm={props.onConfirm}
      onClose={props.onClose}
    />
  )
}

export default ExportDialog
