import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  GetEnabledLanguages = 'api/languages',
}



//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

type LanguagesResponse = {
  languages: {
    id: number
    name: string
    code: string
    locale: string
    enabled: boolean
  }[]
}

enum LanguagesResponseError {
}

export async function getLanguages(output?: RequestOutput<LanguagesResponse, LanguagesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetEnabledLanguages,
    output: output,
  })
}
