import { Page } from 'app/entities/types'
import { SEOData, SEOScoreType, SEOScoreDevice, seoScoreConditions } from 'app/utils'

export function pageMetatagStatus(page: Page, device: SEOScoreDevice, mode: 'quality' | 'exist' = 'quality'): SEOScoreType {
  const translation = page.translation
  if (translation == null) return 'bad'
  if (translation.length === 0) return 'bad'

  const meta = translation[0].meta
  if (meta == null) return 'bad'

  if (device === 'desktop') return getDesktopScore(meta)
  else if (device === 'mobile') return getMobileScore(meta)
  else if (device === 'both') {
    const desktopScore = getDesktopScore(meta)
    const mobileScore = getMobileScore(meta)

    if (desktopScore === 'bad' || mobileScore === 'bad') return 'bad'
    if (desktopScore === 'mediocre' || mobileScore === 'mediocre') return 'mediocre'
    if (desktopScore === 'good' || mobileScore === 'good') return 'good'
  }
  return 'bad'

  function getDesktopScore(meta: SEOData): SEOScoreType {
    if (mode === 'quality') {
      let title: SEOScoreType = seoScoreConditions(meta, 'desktopTitle')
      let description = seoScoreConditions(meta, 'desktopDescription')
      if (title === 'bad' || description === 'bad') return 'bad'
      if (title === 'mediocre' || description === 'mediocre') return 'mediocre'
      if (title === 'good' || description === 'good') return 'good'
    }
    else if (mode === 'exist') {
      if ((meta.desktop.metaTitleDesktop == null || meta.desktop.metaTitleDesktop === '')
        && (meta.desktop.metaDescriptionDesktop == null || meta.desktop.metaDescriptionDesktop === '')) return 'bad'
      if ((meta.desktop.metaTitleDesktop == null || meta.desktop.metaTitleDesktop === '')
        || (meta.desktop.metaDescriptionDesktop == null || meta.desktop.metaDescriptionDesktop === '')) return 'mediocre'
      return 'good'
    }
    return 'bad'
  }
  function getMobileScore(meta: SEOData): SEOScoreType {
    if (mode === 'quality') {
      let title: SEOScoreType = seoScoreConditions(meta, 'mobileTitle')
      let description = seoScoreConditions(meta, 'mobileDescription')
      if (title === 'bad' || description === 'bad') return 'bad'
      if (title === 'mediocre' || description === 'mediocre') return 'mediocre'
      if (title === 'good' || description === 'good') return 'good'
    }
    else if (mode === 'exist') {
      if ((meta.mobile.metaTitleMobile == null || meta.mobile.metaTitleMobile === '')
        && (meta.mobile.metaDescriptionMobile == null || meta.mobile.metaDescriptionMobile === '')) return 'bad'
      if ((meta.mobile.metaTitleMobile == null || meta.mobile.metaTitleMobile === '')
        || (meta.mobile.metaDescriptionMobile == null || meta.mobile.metaDescriptionMobile === '')) return 'mediocre'
      return 'good'
    }
    return 'bad'
  }
}
