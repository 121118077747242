import React from 'react'
import { InputBaseProps, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Color, Value } from 'theme/style'
import { useLocalization } from 'components/methods'

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(1),
    position: 'relative',
    backgroundColor: Color.Background,
    borderRadius: Value.BorderRadius_Field,
    '&:hover div:first-of-type': {
      opacity: 1,
    }
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: Color.Background,
    display: 'flex',
    opacity: 0,
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.2s',
  },
  image: {
    height: '200px',
    width: '100%',
    cursor: 'pointer',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
}))

interface ButtonFieldProps extends InputBaseProps {
  onClick?: () => void
  onRemove?: () => void
  onUpdate?: (name: string, value: any) => void
  onConfirm?: (name: string, value: any) => void
}
const ButtonField = ({ ...props }: ButtonFieldProps) => {
  const { t } = useLocalization()
  const classes = useStyles()



  //CLICK

  function click() {
    if (props.onClick != null) props.onClick()
  }

  function remove() {
    if (props.onRemove != null) props.onRemove()
  }



  //RENDER

  return (
    <>
      <div className={classes.imageContainer}>
        <div className={classes.overlay}>
          <Button variant="text" onClick={remove}>{t('common.field.image.action.remove')}</Button>
          <Button variant="text" onClick={click}>{t('common.field.image.action.assign')}</Button>
        </div>
        <div className={classes.image} style={{ backgroundImage: `url(${props.value})` }}>
        </div>
      </div>
    </>
  )
}

export default ButtonField