import { Value } from 'theme/style'

const style = {
  root: {
  },
  rounded: {
    borderRadius: Value.BorderRadius,
  },
  elevation1: {
    boxShadow: '12px 12px 24px 0 rgba(0,0,0,0.08)'
  },
  elevation8: {
    boxShadow: '12px 12px 24px 0 rgba(0,0,0,0.08)'
  },
}

export default style
