import { Card, makeStyles } from '@material-ui/core';
import {
  getAllTaxonomies, GetTaxonomiesRequestParams, GetTaxonomiesResponse,
  addTaxonomyToPage, AddTaxonomyToPageRequestParams,
  removeTaxonomyFromPage, RemoveTaxonomyFromPageRequestParams
} from 'app/api';
import { CardContent, CardHeader, CardField, PageEntityDetailsRefreshableProps } from 'components';
import { useLocalization } from 'components/methods';
import { TaxonomablePage, Taxonomy, Language } from 'app/entities/types';
import { toast } from 'app/utils';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { PageTypeValue } from 'app/values';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  taxonomiesContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  taxonomy: {
    margin: theme.spacing(0.5),
  },
}))

enum InputType {
  Taxonomy = 'fieldTaxonomy',
}

interface TaxonomiesProps extends PageEntityDetailsRefreshableProps {
  className?: string,
  object: TaxonomablePage,
  language: Language,
  pageType: PageTypeValue | null
  taxonomiesList?: Taxonomy[] | null,
}

const CardTaxonomies = ({ ...props }: TaxonomiesProps) => {
  const classes = useStyles()
  const { t } = useLocalization()

  const [isLoading, setIsLoading] = useState(false)

  const [taxonomies, setTaxonomies] = useState<Taxonomy[] | null>(props.taxonomiesList ?? [])
  const [activeTaxonomies, setActiveTaxonomies] = useState<Taxonomy[]>([])

  useEffect(() => {
    fetchTaxonomies()
  }, [])

  useEffect(() => {
    if (props.object.taxonomies == null) return
    if (taxonomies == null) return

    let items: Taxonomy[] = []
    taxonomies.forEach(t => {
      if (props.object.taxonomies?.find(tax => t.id === tax.id) != null) {
        items.push(t)
      }
    })
    setActiveTaxonomies(items)
  }, [taxonomies])

  const fetchTaxonomies = () => {
    setIsLoading(true)
    const encode = (): GetTaxonomiesRequestParams => {
      return {
        languageId: props.language.id,
        pageTypeKeyword: props.pageType
      }
    }

    const decode = (data: GetTaxonomiesResponse): Taxonomy[] => {
      let object: Taxonomy[] = []
      data.taxonomies.forEach((taxonomy, i) => {
        object.push({
          id: taxonomy.id,
          keyword: taxonomy.keyword,
          published: taxonomy.published,
          parentId: taxonomy.parentId,
          pageId: taxonomy.pageId,
          referencePageTypeId: taxonomy.pageTypeId,
          translation: taxonomy.translation
        })
      })
      return object
    }

    getAllTaxonomies(encode(), {
      response(data) {
        const taxonomies = decode(data)
        setTaxonomies(taxonomies)
        setIsLoading(false)
      },
      error(error, message) {
        toast.error(message)
        setIsLoading(false)
      }
    })
  }

  function updateFieldValue(name: string, value: any, action?: string, object?: Taxonomy) {
    switch (name) {
      case InputType.Taxonomy: {
        setActiveTaxonomies(value)
        updateTaxonomies(object, action)
        break
      }
    }
  }

  function updateTaxonomies(taxonomy?: Taxonomy, action?: string) {
    if (props.object == null) return
    if (taxonomy == null) return
    if (action == null) return

    const addTaxonomy = (taxonomy: Taxonomy) => {
      const params: AddTaxonomyToPageRequestParams = {
        pageId: props.object.pageId,
        taxonomyId: taxonomy.id
      }

      addTaxonomyToPage(params, {
        response(data) {
          toast.success('Categoria aggiunta')
          if (props.refreshObject != null) props.refreshObject()
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }

    const removeTaxonomy = (taxonomy: Taxonomy) => {
      const params: RemoveTaxonomyFromPageRequestParams = {
        pageId: props.object.pageId,
        taxonomyId: taxonomy.id
      }

      removeTaxonomyFromPage(params, {
        response(data) {
          toast.success('Categoria rimossa')
          if (props.refreshObject != null) props.refreshObject()
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }

    switch (action) {
      case 'add':
        addTaxonomy(taxonomy)
        break

      case 'remove':
        removeTaxonomy(taxonomy)
        break

      default:
        break
    }
  }

  return (
    <Card className={clsx(classes.root, props.className)}>
      <CardHeader title={t('component.CardPageTaxonomies.title')} iconName="tags" isLoading={isLoading} />
      <CardContent isLoading={isLoading}>
        <CardField type={'tags'} name={InputType.Taxonomy}
          placeholder={t('common.search')}
          items={taxonomies ?? []} activeItems={activeTaxonomies}
          itemValueConstructor={(option) => option.translation[0].name ?? ''}
          onUpdate={updateFieldValue}
        />
      </CardContent>
    </Card>
  )
}

export default CardTaxonomies