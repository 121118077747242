import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'
import { API_Policy } from 'app/api/project/policies'

enum APIEndpoint {
  GetAllClaims = 'api/claims',
  GetClaimById = 'api/claims/getById',
  CreateClaim = 'api/claims/create',
  UpdateClaim = 'api/claims/update',
  DeleteClaim = 'api/claims/delete',
  UpdateClaimStatus = 'api/claims/updateStatus',
  GetClaimFiles = 'api/claims/getGallery',
  AddClaimFile = 'api/claims/addGalleryImage',
  RemoveClaimFile = 'api/claims/removeGalleryImage',
}

export interface API_Claim {
  id: number
  creationDate?: Date | null
  claimDate?: Date | null
  claimNumber?: string | null
  claimStatus?: API_ClaimStatus | null
  address?: string | null
  businessName?: string | null
  city?: string | null
  email?: string | null
  firstname?: string | null
  lastname?: string | null
  fiscalCode?: string | null
  internalNotes?: string | null
  message?: string | null
  phone?: string | null
  zip?: string | null
  insuredAmount?: number | null
  claimAmount?: number | null
  claimCourier?: string | null
  claimParcelDamaged?: boolean | null
  claimType?: API_ClaimType | null
  claimTime?: API_ClaimTime | null
  policy?: API_Policy
}
export interface API_ClaimType {
  id: number
  name?: string | null
  keyword?: string | null
}
export interface API_ClaimTime {
  id: number
  name?: string | null
  keyword?: string | null
}
export interface API_ClaimStatus {
  id: number
  name?: string | null
  keyword?: string | null
}



export interface GetAllClaimsRequestParams extends APIPaginatable, APISortable, APISearchable {
}
export interface GetAllClaimsResponse extends APIPaginatableResponse {
  claims: API_Claim[]
}
enum GetAllClaimsResponseError {
}
export async function getAllClaims(params: GetAllClaimsRequestParams, output?: RequestOutput<GetAllClaimsResponse, GetAllClaimsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllClaims,
    params: params,
    output: output,
  })
}



export type GetClaimRequestParams = {
  id: number
}
export type GetClaimResponse = {
  claim: API_Claim
}
enum GetClaimResponseError {
}
export async function getClaim(params: GetClaimRequestParams, output?: RequestOutput<GetClaimResponse, GetClaimResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetClaimById,
    params: params,
    output: output,
  })
}



export type CreateClaimRequestParams = {
}
type CreateClaimResponse = {
  claim: API_Claim
}
enum CreateClaimReponseError {
}
export async function createClaim(params: CreateClaimRequestParams, output?: RequestOutput<CreateClaimResponse, CreateClaimReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateClaim,
    params: params,
    output: output,
  })
}



export interface UpdateClaimRequestParams extends API_Claim {
  claimTypeId?: number | null
  claimTimeId?: number | null
  claimStatusId?: number | null
  policyId?: number | null
}
type UpdateClaimResponse = {
}
enum UpdateClaimReponseError {
}
export async function updateClaim(params: UpdateClaimRequestParams, output?: RequestOutput<UpdateClaimResponse, UpdateClaimReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateClaim,
    params: params,
    output: output,
  })
}



export type DeleteClaimRequestParams = {
  id: number
}
type DeleteClaimResponse = {
}
enum DeleteClaimReponseError {
}
export async function deleteClaim(params: DeleteClaimRequestParams, output?: RequestOutput<DeleteClaimResponse, DeleteClaimReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteClaim,
    params: params,
    output: output,
  })
}



export type UpdateClaimStatusRequestParams = {
  id: number
}
type UpdateClaimStatusResponse = {
}
enum UpdateClaimStatusReponseError {
}
export async function updateClaimStatus(params: UpdateClaimStatusRequestParams, output?: RequestOutput<UpdateClaimStatusResponse, UpdateClaimStatusReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateClaimStatus,
    params: params,
    output: output,
  })
}



export interface GetClaimFilesRequestParams {
  claimId: number
}
export interface GetClaimFilesResponse {
  claimGallery: {
    id: number
    itemOrder: number
    file: string
  }[]
}
enum GetClaimFilesResponseError {
}
export async function getClaimFiles(params: GetClaimFilesRequestParams, output?: RequestOutput<GetClaimFilesResponse, GetClaimFilesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetClaimFiles,
    params: params,
    output: output,
  })
}



export interface AddClaimFileRequestParams {
  claimId: number
  file: string
}
export interface AddClaimFileResponse {
}
enum AddClaimFileResponseError {
}
export async function addClaimFile(params: AddClaimFileRequestParams, output?: RequestOutput<AddClaimFileResponse, AddClaimFileResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.AddClaimFile,
    params: params,
    output: output,
  })
}



export interface RemoveClaimFileRequestParams {
  id: number
}
export interface RemoveClaimFileResponse {
}
enum RemoveClaimFileResponseError {
}
export async function removeClaimFile(params: RemoveClaimFileRequestParams, output?: RequestOutput<RemoveClaimFileResponse, RemoveClaimFileResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.RemoveClaimFile,
    params: params,
    output: output,
  })
}