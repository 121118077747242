import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'

enum APIEndpoint {
  GetAllClients = 'api/clients',
  GetClientById = 'api/clients/getById',
  CreateClient = 'api/clients/create',
  UpdateClient = 'api/clients/update',
  DeleteClient = 'api/clients/delete',
}

export interface API_Client {
  id: number
  clientNumber?: string | null
  address?: string | null
  businessName?: string | null
  city?: string | null
  email?: string | null
  firstname?: string | null
  lastname?: string | null
  fiscalCode?: string | null
  internalNotes?: string | null
  phone?: string | null
  zip?: string | null
}



export interface GetAllClientsRequestParams extends APIPaginatable, APISortable, APISearchable {
}
export interface GetAllClientsResponse extends APIPaginatableResponse {
  clients: API_Client[]
}
enum GetAllClientsResponseError {
}
export async function getAllClients(params: GetAllClientsRequestParams, output?: RequestOutput<GetAllClientsResponse, GetAllClientsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllClients,
    params: params,
    output: output,
  })
}



export type GetClientRequestParams = {
  id: number
}
export type GetClientResponse = {
  client: API_Client
}
enum GetClientResponseError {
}
export async function getClient(params: GetClientRequestParams, output?: RequestOutput<GetClientResponse, GetClientResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetClientById,
    params: params,
    output: output,
  })
}



export type CreateClientRequestParams = {
}
type CreateClientResponse = {
  client: API_Client
}
enum CreateClientReponseError {
}
export async function createClient(params: CreateClientRequestParams, output?: RequestOutput<CreateClientResponse, CreateClientReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateClient,
    params: params,
    output: output,
  })
}



export interface UpdateClientRequestParams extends API_Client {
}
type UpdateClientResponse = {
}
enum UpdateClientReponseError {
}
export async function updateClient(params: UpdateClientRequestParams, output?: RequestOutput<UpdateClientResponse, UpdateClientReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateClient,
    params: params,
    output: output,
  })
}



export type DeleteClientRequestParams = {
  id: number
}
type DeleteClientResponse = {
}
enum DeleteClientReponseError {
}
export async function deleteClient(params: DeleteClientRequestParams, output?: RequestOutput<DeleteClientResponse, DeleteClientReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteClient,
    params: params,
    output: output,
  })
}