import { Select, MenuItem, InputProps, makeStyles } from '@material-ui/core';
import { FieldGroup } from 'app/entities/types';
import { useState, useEffect } from 'react';
import { FieldGroupTypeSelectOptionKeywords } from 'app/values';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  input: {
  },
}))


type SelectOption = {
  [FieldGroupTypeSelectOptionKeywords.Value]: string
  [FieldGroupTypeSelectOptionKeywords.Selected]: boolean
}

interface FieldInputProps extends InputProps {
  className?: string
  value: string
  fieldGroup: FieldGroup
  updateValueCallback: (value: string, hasError: boolean) => void
  onBlur: (event: any) => void
}

const FieldInput = ({ className, value, fieldGroup, updateValueCallback, onBlur }: FieldInputProps) => {
  const classes = useStyles()
  const [options, setOptions] = useState<SelectOption[]>([])

  useEffect(() => {
    let opt: SelectOption[] = []

    fieldGroup.childs.forEach(c => {
      const selectedValue = c.fields.filter(f => f.keyword === FieldGroupTypeSelectOptionKeywords.Selected)[0]
      const fieldValue = selectedValue.translation?.value
      const isSelected = (fieldValue === '0' || fieldValue === '') ? false : true
      opt.push({
        value: c.name,
        selected: isSelected
      })
    })

    setOptions(opt)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const selected = options.filter(o => o.selected === true && o.selected != null)
    if (selected.length > 0) {
      const v = selected[0].value
      updateValueCallback(v, hasError(v))
    }
  }, [options])

  const hasError = (v?: string) => {
    return false
  }

  function handleInputChange(event: any) {
    const target = event.target as HTMLInputElement
    let value = target.value

    updateValueCallback(value, hasError(value))
  }

  return (
    <Select disableUnderline className={clsx(className, classes.input)}
      value={value}
      onChange={e => handleInputChange(e)}
      onBlur={e => onBlur(e)}
    >
      {options.map((o, i) => (
        <MenuItem key={i} value={o.value}>{o.value}</MenuItem>
      ))}
    </Select>
  )
}

export default FieldInput
