import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  GetAllClaimStatuses = 'api/claimStatuses',
}



export type GetAllClaimStatusesResponse = {
  claimStatuses: {
    id: number,
    name: string
    keyword: string
  }[]
}

enum GetAllClaimStatusesResponseError {
}

export async function getAllClaimStatuses(output?: RequestOutput<GetAllClaimStatusesResponse, GetAllClaimStatusesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllClaimStatuses,
    output: output,
  })
}
