import { Button, Input, InputProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { MediaLibraryFile } from 'app/entities/types'
import { MediaLibraryFilePicker } from 'components'
import { MediaLibraryFolderValue } from 'app/values';

const useStyles = makeStyles((theme) => ({
  input: {
  },
}))


interface FieldInputProps extends InputProps {
  className?: string
  value: string | null
  updateValueCallback: (value: string | null, hasError: boolean) => void
  onBlur: (event: any) => void
}

const FieldInput = ({ className, value, updateValueCallback, onBlur }: FieldInputProps) => {
  const classes = useStyles()

  const [imagePickerIsOpen, setImagePickerIsOpen] = useState(false)
  // const [options, setOptions] = useState<string[]>([])

  useEffect(() => {
    // fetchOptions()
    updateValueCallback(value, hasError())

    // function fetchOptions() {
    //   const encode = (): GetAllPagesRequestParams => {
    //     return {
    //       languageId: 1,
    //       pageTypeKeyword: PageTypeValue.Default
    //     }
    //   }

    //   const decode = (data: GetAllPagesResponse): string[] => {
    //     let object: string[] = []
    //     data.pages.map((page, i) => {
    //       object.push(page.translation[0].name ?? '')
    //     })
    //     return object
    //   }

    //   getAllPages(encode(), {
    //     response(data) {
    //       const pages = decode(data)
    //       // setOptions(pages)
    //     },
    //     error(error, message) {
    //       toast.error(message)
    //     }
    //   })
    // }

  }, [])

  const hasError = (v?: string) => {
    return false
  }

  // function handleInputChange(event: any) {
  //   const target = event.target as HTMLInputElement
  //   let value = target.value

  //   // const newValue = parseInt(value)

  //   // updateValueCallback(formattedValue, hasError(formattedValue))
  // }


  function openImagePicker() {
    setImagePickerIsOpen(true)
  }

  function closeImagePicker() {
    setImagePickerIsOpen(false)
  }

  function imagePickerSelected(selectedItem: MediaLibraryFile | null) {
    if (selectedItem == null) return
    const value = String(selectedItem.id)
    updateValueCallback(value, hasError(value))
  }

  function imagePickerConfirm(selectedItem: MediaLibraryFile | null) {
    onBlur(null)
  }

  return (
    <>
      <Input disableUnderline className={clsx(className, classes.input)}
        disabled
        value={value}
        type="text"
        placeholder="Inserisci un testo..."
      />
      <Button variant="text" color="secondary" onClick={openImagePicker}>Modifica immagine</Button>
      <MediaLibraryFilePicker mediaLibraryFolder={MediaLibraryFolderValue.General}
        enableUpload={true} open={imagePickerIsOpen} onSelectCallback={imagePickerSelected}
        onConfirmCallback={imagePickerConfirm} onClose={closeImagePicker} />
    </>
  )
}

export default FieldInput
