import typography from 'theme/config/typography';

const style = {
  root: {
    ...typography.h3,
    padding: '20px',
  },
}

export default style
