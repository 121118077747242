import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  GetAllOrderStatuses = 'api/orderStatuses',
}



export type GetAllOrderStatusesResponse = {
  orderStatuses: {
    id: number,
    name: string
    keyword: string
  }[]
}

enum GetAllOrderStatusesResponseError {
}

export async function getAllOrderStatuses(output?: RequestOutput<GetAllOrderStatusesResponse, GetAllOrderStatusesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllOrderStatuses,
    output: output,
  })
}
