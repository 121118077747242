import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  UploadMediaLibraryFile = 'api/mediaLibrary/files/upload',
  GetMediaLibraryFileById = 'api/mediaLibrary/files/getById',
  GetMediaLibraryFileTranslation = 'api/mediaLibrary/files/getTranslation',
  UpdateMediaLibraryFileTranslation = 'api/mediaLibrary/files/updateTranslation',
}



export type UploadMediaLibraryFileRequestParams = {
  filename: string
  folderId: number
  fileData: string
}

export type UploadMediaLibraryFileResponse = {
}

enum UploadMediaLibraryFileResponseError {
}

export async function uploadMediaLibraryFile(params: UploadMediaLibraryFileRequestParams, output?: RequestOutput<UploadMediaLibraryFileResponse, UploadMediaLibraryFileResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UploadMediaLibraryFile,
    params: params,
    output: output,
  })
}




export type GetMediaLibraryFileRequestParams = {
  id: number
}
export type GetMediaLibraryFileResponse = {
  file: {
    id: number
    filename: string,
    type: string,
    basepath: string
    path: string
    folder: number
  }
}
enum GetMediaLibraryFileResponseError {
}
export async function getMediaLibraryFile(params: GetMediaLibraryFileRequestParams, output?: RequestOutput<GetMediaLibraryFileResponse, GetMediaLibraryFileResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetMediaLibraryFileById,
    params: params,
    output: output,
  })
}





export type GetMediaLibraryFileTranslationRequestParams = {
  mediaFileId: number
  languageId: number
}
export type GetMediaLibraryFileTranslationResponse = {
  mediaFileTranslation: {
    id: number,
    title: string
    description: string
    alt: string,
    languageId: number
  }
}
enum GetMediaLibraryFileTranslationResponseError {
}
export async function getMediaLibraryFileTranslation(params: GetMediaLibraryFileTranslationRequestParams, output?: RequestOutput<GetMediaLibraryFileTranslationResponse, GetMediaLibraryFileTranslationResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetMediaLibraryFileTranslation,
    params: params,
    output: output,
  })
}



export type UpdateMediaLibraryFileTranslationRequestParams = {
  id: number,
  title: string | null
  description: string | null
  alt: string | null,
}

type UpdateMediaLibraryFileTranslationResponse = {
}

enum UpdateMediaLibraryFileTranslationReponseError {
}

export async function updateMediaLibraryFileTranslation(params: UpdateMediaLibraryFileTranslationRequestParams, output?: RequestOutput<UpdateMediaLibraryFileTranslationResponse, UpdateMediaLibraryFileTranslationReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateMediaLibraryFileTranslation,
    params: params,
    output: output,
  })
}