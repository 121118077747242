import React from 'react'
import { makeStyles, CircularProgress, CircularProgressProps } from '@material-ui/core'
import { Color } from 'theme/style'
import { fade } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    position: 'absolute',
    left: 0,
  },
  top: {
    animationDuration: '600ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}))

interface SpinnerProps extends CircularProgressProps {
  type?: 'default' | 'small'
  spinnerColor?: string
}

const Spinner = ({ ...props }: SpinnerProps) => {
  const classes = useStyles()

  const typeValue = props.type ?? 'default'
  const spinnerColorValue = props.spinnerColor ?? Color.Spinner

  let sizeValue = 40
  if (typeValue === 'small') sizeValue = 16
  if (typeValue === 'default') sizeValue = 20

  let thickness = 4
  if (typeValue === 'small') thickness = 4
  if (typeValue === 'default') thickness = 5

  return (
    <div className={classes.root} style={{ width: sizeValue, height: sizeValue }}>
      <CircularProgress {...props} variant="determinate" className={classes.bottom} size={sizeValue} thickness={thickness} value={100} style={{
        color: fade(spinnerColorValue, 0.2)
      }} />
      <CircularProgress {...props} variant="indeterminate" disableShrink className={classes.top} size={sizeValue} thickness={thickness} classes={{
        circle: classes.circle,
      }} style={{
        color: spinnerColorValue
      }}
      />
    </div>
  )
}

export default Spinner
