import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  GetAllMediaLibraryFolders = 'api/mediaLibrary/folders',
  GetMediaLibraryFolderById = 'api/mediaLibrary/folders/getById',
  CreateMediaLibraryFolder = 'api/mediaLibrary/folders/create',
  UpdateMediaLibraryFolder = 'api/mediaLibrary/folders/update',
  DeleteMediaLibraryFolder = 'api/mediaLibrary/folders/delete',
}



export type GetAllMediaLibraryFoldersResponse = {
  folders: {
    id: number
    keyword: string
    parentId: number | null
    isIndexed: boolean
  }[]
}

enum GetAllMediaLibraryFoldersResponseError {
}

export async function getAllMediaLibraryFolders(output?: RequestOutput<GetAllMediaLibraryFoldersResponse, GetAllMediaLibraryFoldersResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllMediaLibraryFolders,
    output: output,
  })
}



export type GetMediaLibraryFolderRequestParams = {
  id: number
  languageId: number
}
export type GetMediaLibraryFolderResponse = {
  folder: {
    id: number
    keyword: string
    parentId: number | null
    isIndexed: boolean
    files?: {
      id: number
      filename: string,
      type: string,
      basepath: string
      path: string
      folder: number
      translation: {
        id: number
        title: string
        description: string
        alt: string
      }
    }[]
  }
}

enum GetMediaLibraryFolderResponseError {
}

export async function getMediaLibraryFolder(params: GetMediaLibraryFolderRequestParams, output?: RequestOutput<GetMediaLibraryFolderResponse, GetMediaLibraryFolderResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetMediaLibraryFolderById,
    params: params,
    output: output,
  })
}



export type CreateMediaLibraryFolderRequestParams = {
  keyword: string
  parentId: number | null
  isIndexed: boolean
}

type CreateMediaLibraryFolderResponse = {
  folder: {
    id: number
    keyword: string
    parentId: number | null
    isIndexed: boolean
  }
}

enum CreateMediaLibraryFolderResponseError {
}

export async function createMediaLibraryFolder(params: CreateMediaLibraryFolderRequestParams, output?: RequestOutput<CreateMediaLibraryFolderResponse, CreateMediaLibraryFolderResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateMediaLibraryFolder,
    params: params,
    output: output,
  })
}



export type UpdateMediaLibraryFolderRequestParams = {
  id: number
  keyword: string
  parentId: number | null
  isIndexed: boolean
}

type UpdateMediaLibraryFolderResponse = {
}

enum UpdateMediaLibraryFolderResponseError {
}

export async function updateMediaLibraryFolder(params: UpdateMediaLibraryFolderRequestParams, output?: RequestOutput<UpdateMediaLibraryFolderResponse, UpdateMediaLibraryFolderResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateMediaLibraryFolder,
    params: params,
    output: output,
  })
}



export type DeleteMediaLibraryFolderRequestParams = {
  id: number
}

type DeleteMediaLibraryFolderResponse = {
}

enum DeleteMediaLibraryFolderResponseError {
}

export async function deleteMediaLibraryFolder(params: DeleteMediaLibraryFolderRequestParams, output?: RequestOutput<DeleteMediaLibraryFolderResponse, DeleteMediaLibraryFolderResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteMediaLibraryFolder,
    params: params,
    output: output,
  })
}