import spacing from 'theme/config/spacing'
import { Value } from 'theme/style'

const style = {
  paper: {
    padding: spacing(2),
    borderRadius: Value.BorderRadius,
  },
}

export default style
