import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  GetSectionsByPageId = 'api/sections/getByPageId',
  GetAllSharedSections = 'api/sections/getShared',
  GetAllGlobalSections = 'api/sections/getGlobal',
  CreateSection = 'api/sections/create',
  UpdateSection = 'api/sections/update',
  DeleteSection = 'api/sections/delete',
  GetContentsBySectionId = 'api/sections/getContentsBySectionId',
  CreateContent = 'api/sections/addContent',
  UpdateContent = 'api/sections/updateContent',
  DeleteContent = 'api/sections/deleteContent',
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export type GetSectionsRequestParams = {
  id: number
  withContent: boolean
  languageId?: number
}
export type GetSectionsResponse = {
  sections: {
    id: number
    name: string
    keyword: string
    itemOrder: number
    shared: boolean
  }[]
}

enum GetSectionsResponseError {
}

export async function getSections(params: GetSectionsRequestParams, output?: RequestOutput<GetSectionsResponse, GetSectionsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetSectionsByPageId,
    params: params,
    output: output,
  })
}




export type GetAllSharedSectionsResponse = {
  sections: {
    id: number
    name: string
    keyword: string
    itemOrder: number
    shared: boolean
    global: boolean
  }[]
}

enum GetAllSharedSectionsResponseError {
}

export async function getAllSharedSections(output?: RequestOutput<GetAllSharedSectionsResponse, GetAllSharedSectionsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllSharedSections,
    output: output,
  })
}



export type GetAllGlobalSectionsRequestParams = {
  withContent: boolean
  languageId?: number
}

export type GetAllGlobalSectionsResponse = {
  sections: {
    id: number
    name: string
    keyword: string
    itemOrder: number
    shared: boolean
    global: boolean
  }[]
}

enum GetAllGlobalSectionsResponseError {
}

export async function getAllGlobalSections(params: GetAllGlobalSectionsRequestParams, output?: RequestOutput<GetAllGlobalSectionsResponse, GetAllGlobalSectionsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllGlobalSections,
    params: params,
    output: output,
  })
}



//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export type CreateSectionRequestParams = {
  name: string
  keyword: string
  itemOrder: number
  shared: boolean
  global?: boolean
  pageId: number
}

type CreateSectionResponse = {
  sections: {
    id: number
  }[]
}

enum CreateSectionResponseError {
}

export async function createSection(params: CreateSectionRequestParams, output?: RequestOutput<CreateSectionResponse, CreateSectionResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateSection,
    params: params,
    output: output,
  })
}



//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export type UpdateSectionRequestParams = {
  id: number
  name: string
  keyword: string
  itemOrder: number
  shared: boolean
  pageId: number
}

type UpdateSectionResponse = {
  sections: {
    id: number
  }[]
}

enum UpdateSectionResponseError {
}

export async function updateSection(params: UpdateSectionRequestParams, output?: RequestOutput<UpdateSectionResponse, UpdateSectionResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateSection,
    params: params,
    output: output,
  })
}



//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export type DeleteSectionRequestParams = {
  id: number
  pageId: number
  deleteShared: boolean
}
type DeleteSectionResponse = {
}

enum DeleteSectionResponseError {
}

export async function deleteSection(params: DeleteSectionRequestParams, output?: RequestOutput<DeleteSectionResponse, DeleteSectionResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteSection,
    params: params,
    output: output,
  })
}



//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export type GetContentsRequestParams = {
  id: number
  languageId: number
}

export type GetContentsResponse = {
  contents: {
    id: number
    name: string
    sectionId: number
    itemOrder: number
  }[]
}

enum GetContentsResponseError {
}

export async function getContents(params: GetContentsRequestParams, output?: RequestOutput<GetContentsResponse, GetContentsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetContentsBySectionId,
    params: params,
    output: output,
  })
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export type AddContentRequestParams = {
  name: string
  sectionId: number
  itemOrder: number
}
type AddContentResponse = {
}

enum AddContentResponseError {
}

export async function createContent(params: AddContentRequestParams, output?: RequestOutput<AddContentResponse, AddContentResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateContent,
    params: params,
    output: output,
  })
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export type UpdateContentRequestParams = {
  id: number
  name: string
  itemOrder: number
}
type UpdateContentResponse = {
}

enum UpdateContentResponseError {
}

export async function updateContent(params: UpdateContentRequestParams, output?: RequestOutput<UpdateContentResponse, UpdateContentResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateContent,
    params: params,
    output: output,
  })
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export type DeleteContentRequestParams = {
  id: number
}
type DeleteContentResponse = {
}

enum DeleteContentResponseError {
}

export async function deleteContent(params: DeleteContentRequestParams, output?: RequestOutput<DeleteContentResponse, DeleteContentResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteContent,
    params: params,
    output: output,
  })
}