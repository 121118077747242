import React from 'react'
import { useRouter } from 'app/utils'

export type AuthGuardParams = {
  check: boolean,
  onSuccess?: string,
  onDeny?: string
}
interface AuthGuardProps {
  guards: AuthGuardParams[]
  children?: React.ReactNode
}

const AuthGuard = ({ guards, children }: AuthGuardProps) => {
  const router = useRouter();

  guards.forEach(g => {
    if (g.check === true) {
      if (g.onSuccess) {
        router.history.push(g.onSuccess)
        return null
      }
    }
    if (g.check === false) {
      if (g.onDeny) {
        router.history.push(g.onDeny)
        return null
      }
    }
  })

  return <>{children}</>
}

export default AuthGuard
