import { TagValue } from 'app/values'
import { Tag } from 'app/entities/types'


function tagIdentifier(t: Tag): string {
  return t.keyword
}

export function tagIsOfType(t: Tag, v: TagValue): boolean {
  if (t == null) return false
  return tagIdentifier(t) === v
}

