import { Chip, makeStyles, TextField } from '@material-ui/core';
import React from 'react'
import _ from 'lodash'
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  input: {
    minHeight: '45px',
    display: 'flex'
  },
  textField: {
    backgroundColor: 'transparent',
  }
}))

interface TagFieldProps extends AutocompleteProps<any, any, any, any> {
  name: string
  items: any[]
  activeItems: any[]
  itemValueConstructor: (item: any) => string
  itemRenderClass?: (item: any) => string
  onUpdate?: (name: string, value: any, action: string, object: any) => void
  onConfirm?: (name: string, value: any) => void
}
const TagField = ({ ...props }: TagFieldProps) => {
  const classes = useStyles()



  //FIELD CHANGES

  const handleInputChange = (value: any[], event: any) => {
    if (props.activeItems == null) return

    const initialCount = props.activeItems.length
    const nextCount = value.length
    let diff: any[] = []
    let action: 'none' | 'add' | 'remove' = 'none'

    if (initialCount > nextCount) {
      action = 'remove'
      diff = _.difference(props.activeItems, value)
    }
    else if (initialCount < nextCount) {
      action = 'add'
      diff = _.difference(value, props.activeItems)
    }

    if (diff.length > 1) return
    const object = diff[0]

    if (props.onUpdate == null) return
    props.onUpdate(props.name, value, action, object)
  }



  //RENDER

  return (
    <Autocomplete {...props} className={classes.input}
      multiple filterSelectedOptions disableClearable
      value={props.activeItems}
      options={props.items ?? []} getOptionLabel={(option) => props.itemValueConstructor(option) ?? ''} getOptionSelected={(option, value) => option.id === value.id}
      renderTags={(itemValue, getItemProps) => itemValue.map((option, index) => (<Chip {...getItemProps({ index })} className={props.itemRenderClass && props.itemRenderClass(option)} label={props.itemValueConstructor(option) ?? ''} />))}
      renderInput={(params) => <TextField {...params} className={classes.textField} placeholder={props.placeholder} />}
      onChange={(e, value) => handleInputChange(value, e)}
    />
  )
}

export default TagField