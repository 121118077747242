import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import spacing from 'theme/config/spacing'

const breakpoints = createBreakpoints({})

const style = {
  root: {
    padding: '2rem',
    alignItems: 'center',
    paddingTop: '1rem',
  },
  avatar: {
  },
  content: {
    [breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: spacing(2)
    },
  },
  action: {
    marginTop: 0,
    [breakpoints.down('sm')]: {
      width: '100%'
    },
  }
}

export default style