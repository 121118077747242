import { SessionState } from 'app/session/reducers'
import { Language } from 'app/entities/types'
import { LanguageCodeValue } from 'app/values'

export const getLanguageFromLanguageCode = (session: SessionState, code: LanguageCodeValue): Language | null => {
  const obj = session.app.languages.filter(l => l.code === code)
  return obj.length > 0 ? obj[0] : null
}

export const getLanguageFromList = (languages: Language[], code: LanguageCodeValue): Language | null => {
  const obj = languages.filter(l => l.code === code)
  return obj.length > 0 ? obj[0] : null
}

export const languageIs = (language: Language, code: LanguageCodeValue): boolean => {
  return language.code === code
}