import { VerifyButton, ButtonProps } from 'components'
import { useLocalization } from 'components/methods'

interface DeleteButtonProps extends ButtonProps {
  onConfirm?: () => void
}

const DeleteButton = ({ onConfirm, ...props }: DeleteButtonProps) => {
  const { t } = useLocalization()

  const confirmDelete = () => {
    if (onConfirm != null) onConfirm()
  }

  return (
    <>
      <VerifyButton
        category={'delete'}
        title={t('component.DeleteButton.title')}
        description={t('component.DeleteButton.description')}
        confirmText={t('component.DeleteButton.confirm')}
        confirmButtonCategory={'delete'}
        onConfirm={confirmDelete}>
        {props.children}
      </VerifyButton>
    </>
  )
}

export default DeleteButton
