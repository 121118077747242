import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  GetTags = 'api/tags',
  GetTagById = 'api/tags/getById',
  CreateTag = 'api/tags/create',
  UpdateTag = 'api/tags/update',
  DeleteTag = 'api/tags/delete',
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export type GetTagsResponse = {
  tags: {
    id: number
    keyword: string
    name: string
  }[]
}

enum GetTagsResponseError {
}

export async function getTags(output?: RequestOutput<GetTagsResponse, GetTagsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetTags,
    output: output,
  })
}



export type GetTagRequestParams = {
  id: number
}
export type GetTagResponse = {
  tag: {
    id: number,
    keyword: string
    name: string
  }
}

enum GetTagResponseError {
}

export async function getTag(params: GetTagRequestParams, output?: RequestOutput<GetTagResponse, GetTagResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetTagById,
    params: params,
    output: output,
  })
}



export type CreateTagRequestParams = {
  name: string
}

type CreateTagResponse = {
  tag: {
    id: number
    keyword: string
    name: string
  }
}

enum CreateTagReponseError {
}

export async function createTag(params: CreateTagRequestParams, output?: RequestOutput<CreateTagResponse, CreateTagReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateTag,
    params: params,
    output: output,
  })
}



export type UpdateTagRequestParams = {
  id: number
  keyword: string
  name: string
}

type UpdateTagResponse = {
}

enum UpdateTagReponseError {
}

export async function updateTag(params: UpdateTagRequestParams, output?: RequestOutput<UpdateTagResponse, UpdateTagReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateTag,
    params: params,
    output: output,
  })
}



export type DeleteTagRequestParams = {
  id: number
}

type DeleteTagResponse = {
}

enum DeleteTagReponseError {
}

export async function deleteTag(params: DeleteTagRequestParams, output?: RequestOutput<DeleteTagResponse, DeleteTagReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteTag,
    params: params,
    output: output,
  })
}
