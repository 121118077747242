import React from 'react'
import { Page } from 'components'

const Dashboard = () => {
  return (
    <Page layout={'fullscreen'} title={'Dashboard'}>
    </Page>
  )
}

export default Dashboard
