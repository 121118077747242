import React from 'react'
import clsx from 'clsx'
import { makeStyles, Avatar } from '@material-ui/core'
import { getInitials } from 'app/utils'
import { User } from 'app/entities/types'
import { Color } from 'theme/style';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 50,
    width: 50,
  },
  avatarSmall: {
    height: 25,
    width: 25,
    fontSize: '1.1rem'
  },
  avatarMedium: {
    height: 50,
    width: 50,
    fontSize: '1.6rem'
  },
  avatarLarge: {
    height: 70,
    width: 70,
    fontSize: '2.2rem'
  },
  avatarHuge: {
    height: 120,
    width: 120,
    fontSize: '2.5rem'
  },

  avatarDefault: {
    backgroundColor: Color.Avatar,
    color: Color.AvatarText,
  },
  avatarColor: {
    backgroundColor: Color.AvatarColor,
    color: Color.AvatarColorText,
  }
}))

export type UserAvatarSize = 'small' | 'medium' | 'large' | 'huge'

interface UserAvatarProps {
  className?: string
  user: User | null
  avatarSize?: UserAvatarSize
  color?: boolean
}

const UserAvatar = ({ ...props }: UserAvatarProps) => {
  const classes = useStyles()

  let sizeClass = classes.avatarMedium
  if (props.avatarSize === 'small') sizeClass = classes.avatarSmall
  else if (props.avatarSize === 'medium') sizeClass = classes.avatarMedium
  else if (props.avatarSize === 'large') sizeClass = classes.avatarLarge
  else if (props.avatarSize === 'huge') sizeClass = classes.avatarHuge

  let colorClass = classes.avatarDefault
  if (props.color === true) colorClass = classes.avatarColor

  return (
    <Avatar className={clsx(classes.avatar, sizeClass, colorClass, props.className)} src={props.user?.avatar}>
      {props.user != null ? getInitials(props.user.firstname + ' ' + props.user.lastname) : '–'}
    </Avatar>)
}

export default UserAvatar
