import React, { useState, useEffect } from 'react'
import { InputBaseProps, Menu, MenuItem, IconButton, makeStyles } from '@material-ui/core'
import { Currency } from 'app/entities/types';
import { CurrencySymbol } from 'components';

const useStyles = makeStyles((theme) => ({
  button: {
    height: '40px',
    width: '40px',
    padding: 0,
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  symbol: {
    display: 'flex'
  },
  menuSymbol: {
    marginRight: theme.spacing(1),
    display: 'flex',
  },
  text: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  }
}))

interface CurrencySelectorProps extends InputBaseProps {
  currencies: Currency[]
  selectedCurrency: Currency
  updateCurrencyCallback?: (currency: Currency) => void
}
const CurrencySelector = ({ ...props }: CurrencySelectorProps) => {
  const classes = useStyles()

  const [currentCurrency, setCurrentCurrency] = useState<Currency>()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  useEffect(() => {
    setCurrentCurrency(props.selectedCurrency)
  }, [])

  function handleCurrencyChange(value: number) {
    const currency = props.currencies.filter(l => l.id === value)[0]
    setCurrentCurrency(currency)
    if (props.updateCurrencyCallback != null) props.updateCurrencyCallback(currency)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // Don't show the Currency Selector if there's only one currency available.
  if (props.currencies.length === 1) {
    return (<></>)
  }

  return (
    <>
      <IconButton className={classes.button} onClick={handleClick}>
        {/* <span className={classes.text}>{currentCurrency?.name ?? ''}</span> */}
        <CurrencySymbol className={classes.symbol} currency={currentCurrency} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {props.currencies.map((c, i) => (
          <MenuItem className={classes.menuItem} key={i} onClick={e => { handleClose(); handleCurrencyChange(c.id) }}><CurrencySymbol className={classes.menuSymbol} currency={c} />{c.name}</MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default CurrencySelector
