import { Currency } from 'app/entities/types'
import { CurrencyCodeValue } from 'app/values'

export const getCurrencyCode = (currency: Currency | undefined): string => {
  if (currency == null) return '–'
  return currency.code
}

export const getCurrencySymbol = (currency: Currency | undefined): string => {
  if (currency == null) return '–'
  return currency.htmlSymbol
}

export const currencyIs = (currency: Currency, code: CurrencyCodeValue): boolean => {
  return currency.code === code
}