
export type SEOFieldType = 'desktopTitle' | 'desktopDescription' | 'mobileTitle' | 'mobileDescription'
export type SEOScoreType = 'bad' | 'mediocre' | 'good'
export type SEOScoreDevice = 'desktop' | 'mobile' | 'both'
export type SEOData = {
  desktop: {
    metaTitleDesktop: string | null
    metaDescriptionDesktop: string | null
  },
  mobile: {
    metaTitleMobile: string | null
    metaDescriptionMobile: string | null
  }
}

export function seoScoreConditions(data: SEOData, name: SEOFieldType): SEOScoreType {
  switch (name) {
    case 'desktopTitle':
      if (data.desktop.metaTitleDesktop != null) {
        if (data.desktop.metaTitleDesktop.length <= 20) return 'bad'
        else if (data.desktop.metaTitleDesktop.length <= 70) return 'mediocre'
        else if (data.desktop.metaTitleDesktop.length <= 100) return 'good'
      }
      break

    case 'desktopDescription':
      if (data.desktop.metaDescriptionDesktop != null) {
        if (data.desktop.metaDescriptionDesktop.length <= 60) return 'bad'
        else if (data.desktop.metaDescriptionDesktop.length <= 180) return 'mediocre'
        else if (data.desktop.metaDescriptionDesktop.length <= 255) return 'good'
      }
      break

    case 'mobileTitle':
      if (data.mobile.metaTitleMobile != null) {
        if (data.mobile.metaTitleMobile.length <= 20) return 'bad'
        else if (data.mobile.metaTitleMobile.length <= 70) return 'mediocre'
        else if (data.mobile.metaTitleMobile.length <= 100) return 'good'
      }
      break

    case 'mobileDescription':
      if (data.mobile.metaDescriptionMobile != null) {
        if (data.mobile.metaDescriptionMobile.length <= 60) return 'bad'
        else if (data.mobile.metaDescriptionMobile.length <= 180) return 'mediocre'
        else if (data.mobile.metaDescriptionMobile.length <= 255) return 'good'
      }
      break
  }
  return 'bad'
}