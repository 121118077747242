import palette from 'theme/config/palette';

const style = {
  root: {
    width: 'auto !important',
    '&:focus': {
      backgroundColor: 'transparent !important'
    },
  },
  selectMenu: {
    height: '45px',
    lineHeight: '45px',
    padding: '0px 15px',
    paddingRight: '30px',
  },
  icon: {
    top: 'inherit',
    color: palette.text.secondary,
    width: '2.2rem',
    height: '2.2rem',
    transform: 'scaleY(1.2)',
  }
}

export default style
