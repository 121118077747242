import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'

enum APIEndpoint {
  GetAllArticles = 'api/articles',
  GetArticleById = 'api/articles/getById',
  CreateArticle = 'api/articles/create',
  UpdateArticle = 'api/articles/update',
  DeleteArticle = 'api/articles/delete',
  UpdateArticleStatus = 'api/articles/updatePublished',
  UpdateArticleAuthor = 'api/articles/updateAuthor',
  GetArticleTranslation = 'api/articles/getTranslation',
  UpdateArticleTranslation = 'api/articles/updateTranslation',
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export interface GetArticlesRequestParams extends APIPaginatable, APISortable, APISearchable {
  languageId: number
  taxonomyKeyword?: string
}

export interface GetArticlesResponse extends APIPaginatableResponse {
  articles: {
    id: number
    keyword: string
    published: boolean | null
    pageId: number
    parentId: number | null
    onReview: boolean
    publishDate: Date
    translation: {
      name: string
      description: string
      content: string
    }[],
    taxonomies: {
      id: number
      name: string
      slug: string
      parentId: number | null
    }[]
    author: {
      id: number
      firstname: string
      lastname: string
      username: string
      email: string
      role: {
        id: number
        keyword: string | null
        name: string
        permissions: any
      }
      avatar: string
    }
  }[]
}

enum GetArticlesResponseError {
}

export async function getArticles(params: GetArticlesRequestParams, output?: RequestOutput<GetArticlesResponse, GetArticlesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllArticles,
    params: params,
    output: output,
  })
}



export type GetArticleRequestParams = {
  id: number
  languageId: number
}
export type GetArticleResponse = {
  article: {
    id: number
    keyword: string
    published: boolean | null
    pageId: number
    parentId: number | null
    mainImage: {
      id: number
      type: string
      folder: number
      filename: string
      basepath: string
      path: string
    }
    onReview: boolean
    publishDate: Date
    taxonomies: {
      id: number
      name: string
      slug: string
    }[]
    translation: {
      name: string
      description: string
      content: string
    }[]
    author: {
      id: number
      firstname: string
      lastname: string
      username: string
      email: string
      role: {
        id: number
        keyword: string | null
        name: string
        permissions: any
      }
      avatar: string
    }
  }
}

enum GetArticleResponseError {
}

export async function getArticle(params: GetArticleRequestParams, output?: RequestOutput<GetArticleResponse, GetArticleResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetArticleById,
    params: params,
    output: output,
  })
}



export type CreateArticleRequestParams = {
  parentId: number | null
  onReview: boolean
  authorId: number
  tags: number[]
}

type CreateArticleResponse = {
  article: {
    id: number
    name: string
  }
}

enum CreateArticleReponseError {
}

export async function createArticle(params: CreateArticleRequestParams, output?: RequestOutput<CreateArticleResponse, CreateArticleReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateArticle,
    params: params,
    output: output,
  })
}



export type UpdateArticleRequestParams = {
  id: number
  keyword?: string
  parentId?: number | null
  onReview?: boolean
  publishDate?: string
}

type UpdateArticleResponse = {
}

enum UpdateArticleReponseError {
}

export async function updateArticle(params: UpdateArticleRequestParams, output?: RequestOutput<UpdateArticleResponse, UpdateArticleReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateArticle,
    params: params,
    output: output,
  })
}



export type DeleteArticleRequestParams = {
  id: number
}

type DeleteArticleResponse = {
}

enum DeleteArticleReponseError {
}

export async function deleteArticle(params: DeleteArticleRequestParams, output?: RequestOutput<DeleteArticleResponse, DeleteArticleReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteArticle,
    params: params,
    output: output,
  })
}




export type UpdateArticleStatusRequestParams = {
  id: number
  published: boolean,
}

type UpdateArticleStatusResponse = {
}

enum UpdateArticleStatusReponseError {
}

export async function updateArticleStatus(params: UpdateArticleStatusRequestParams, output?: RequestOutput<UpdateArticleStatusResponse, UpdateArticleStatusReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateArticleStatus,
    params: params,
    output: output,
  })
}




export type UpdateArticleAuthorRequestParams = {
  id: number
  authorId: number,
}

type UpdateArticleAuthorResponse = {
}

enum UpdateArticleAuthorReponseError {
}

export async function updateArticleAuthor(params: UpdateArticleAuthorRequestParams, output?: RequestOutput<UpdateArticleAuthorResponse, UpdateArticleAuthorReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateArticleAuthor,
    params: params,
    output: output,
  })
}




export type GetArticleTranslationRequestParams = {
  articleId: number
  languageId: number
}

export type GetArticleTranslationResponse = {
  articleTranslation: {
    id: number,
    name: string,
    slug: string,
    description: string,
    content: string,
    languageId: number
  }
}

enum GetArticleTranslationResponseError {
}

export async function getArticleTranslation(params: GetArticleTranslationRequestParams, output?: RequestOutput<GetArticleTranslationResponse, GetArticleTranslationResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetArticleTranslation,
    params: params,
    output: output,
  })
}



export type UpdateArticleTranslationRequestParams = {
  id: number,
  name?: string | null,
  slug?: string | null,
  description?: string | null,
  content?: string | null,
}

type UpdateArticleTranslationResponse = {
}

enum UpdateArticleTranslationReponseError {
}

export async function updateArticleTranslation(params: UpdateArticleTranslationRequestParams, output?: RequestOutput<UpdateArticleTranslationResponse, UpdateArticleTranslationReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateArticleTranslation,
    params: params,
    output: output,
  })
}
