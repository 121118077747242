import { Color } from 'theme/style'
import { Tag } from 'app/entities/types'
import { tagIsOfType } from 'app/entities/methods'
import { TagValue } from 'app/values'

export function tagClassFromTag(tag: Tag, classes: any) {
  if (tagIsOfType(tag, TagValue.System)) return classes.tagSystem
  if (tagIsOfType(tag, TagValue.ThankYou)) return classes.tagThankYou
  if (tagIsOfType(tag, TagValue.Onboarding)) return classes.tagOnboarding
  if (tagIsOfType(tag, TagValue.Shop)) return classes.tagShop
  if (tagIsOfType(tag, TagValue.Policy)) return classes.tagPolicy
  return ''
}

export function tagClasses(): any {
  return {
    tagSystem: {
      backgroundColor: Color.TagSystemBackground,
      color: Color.TagSystemText,
      '& > svg': {
        color: 'rgba(255,255,255,0.5)',
        '&:hover': {
          color: 'rgba(255,255,255,0.8)',
        }
      }
    },
    tagThankYou: {
      backgroundColor: Color.TagThankYouBackground,
      color: Color.TagThankYouText,
      '& > svg': {
        color: 'rgba(255,255,255,0.5)',
        '&:hover': {
          color: 'rgba(255,255,255,0.8)',
        }
      }
    },
    tagOnboarding: {
      backgroundColor: Color.TagOnboardingBackground,
      color: Color.TagOnboardingText,
      '& > svg': {
        color: 'rgba(255,255,255,0.5)',
        '&:hover': {
          color: 'rgba(255,255,255,0.8)',
        }
      }
    },
    tagPolicy: {
      backgroundColor: Color.TagPolicyBackground,
      color: Color.TagPolicyText,
      '& > svg': {
        color: 'rgba(255,255,255,0.5)',
        '&:hover': {
          color: 'rgba(255,255,255,0.8)',
        }
      }
    },
    tagShop: {
      backgroundColor: Color.TagShopBackground,
      color: Color.TagShopText,
      '& > svg': {
        color: 'rgba(255,255,255,0.5)',
        '&:hover': {
          color: 'rgba(255,255,255,0.8)',
        }
      }
    },
    tagError: {
      backgroundColor: Color.TagErrorBackground,
      color: Color.TagErrorText,
      '& > svg': {
        color: 'rgba(255,255,255,0.5)',
        '&:hover': {
          color: 'rgba(255,255,255,0.8)',
        }
      }
    },
  }
}