import _ from 'lodash'
import { default as coreRoutes } from './paths/core'
import { default as projectRoutes } from './paths/project'
import { default as coreAuth } from './auth/core'
import { default as projectAuth } from './auth/project'

//Routes
let routes = coreRoutes
projectRoutes.forEach(route => {
	routes.find(r => r.route === '*')?.routes?.splice(-1, 0, route)
})

//Auths
const routesAuth = _.concat(coreAuth, projectAuth)

export { routes, routesAuth }