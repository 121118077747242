/* eslint-disable react/no-multi-comp */
import React from 'react';
import { matchPath } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, Typography } from '@material-ui/core';
import { useLocalization } from 'components/methods'
import useRouter from 'app/utils/useRouter';
import { NavigationListItem } from './components';
import { userHasAccessToRoute } from 'app/entities/methods';
import { useSelector } from 'react-redux';
import { Color } from 'theme/style';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  title: {
    paddingRight: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    color: Color.SidebarTitle,
    fontWeight: 700,
    fontSize: 12,
  },
}));

const NavigationList = props => {
  const { pages, depth, ...rest } = props;
  const { t } = useLocalization()
  const classes = useStyles();
  const session = useSelector((state) => state.session)

  let marginTop = 10;

  if (depth > 0) {
    marginTop = 0;
  }

  const style = {
    marginTop
  };

  const reduceChildRoutes = props => {
    const { router, items, page, depth, t } = props;

    const allowed = userHasAccessToRoute(session.user, page.href)
    if (allowed === false) return items

    if (page.children) {
      const open = matchPath(router.location.pathname, {
        path: page.href,
        exact: false
      });

      items.push(
        <NavigationListItem
          depth={depth}
          href={page.href}
          icon={page.icon}
          key={page.title}
          label={page.label}
          open={Boolean(open)}
          title={t(page.title)}
          onItemClick={props.onItemClick}
        >
          <NavigationList
            depth={depth + 1}
            pages={page.children}
            router={router}
          />
        </NavigationListItem>
      );
    } else {
      items.push(
        <NavigationListItem
          depth={depth}
          href={page.href}
          icon={page.icon}
          key={page.title}
          label={page.label}
          title={t(page.title)}
          onClick={props.onClick}
          onItemClick={props.onItemClick}
        />
      );
    }

    return items;
  };

  return (
    <List className={classes.list} style={style}>
      {pages.reduce(
        (items, page) => reduceChildRoutes({ items, page, depth, t, ...rest }),
        []
      )}
    </List>
  );
};

NavigationList.propTypes = {
  depth: PropTypes.number,
  pages: PropTypes.array,
  onClick: PropTypes.any,
  onItemClick: PropTypes.any
};

const Navigation = props => {
  const { title, pages, className, component: Component, onItemClick, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();
  const { t } = useLocalization()

  return (
    <Component {...rest} className={clsx(classes.root, className)} >
      {title && <Typography className={classes.title} variant="overline">{t(title)}</Typography>}
      <NavigationList depth={0} pages={pages} router={router} onClick={props.onClick} onItemClick={props.onItemClick} />
    </Component>
  );
};

Navigation.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  title: PropTypes.string,
  onClick: PropTypes.any,
  onItemClick: PropTypes.any
};

Navigation.defaultProps = {
  component: 'nav'
};

export default Navigation;
