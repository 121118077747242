import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { Color } from 'theme/style';

const breakpoints = createBreakpoints({})

const style = {
  root: {
    width: '100%',
  },
  spacer: {
    display: 'none'
  },
  toolbar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '2rem !important',
    paddingRight: '1rem !important',
    [breakpoints.down('sm')]: {
      paddingLeft: '10px !important',
      paddingRight: '0px !important',
    },
  },
  selectIcon: {
    top: 'inherit',
  },
  caption: {
    color: Color.TextSecondary,
    opacity: 0.5
  },
  input: {
    opacity: 0.4,
    marginLeft: 0,
    marginBottom: '2px',
    marginRight: 'auto'
  }
}

export default style
