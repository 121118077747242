
const style = {
  root: {
    display: 'flex',
    alignContent: 'flex-start',
    margin: '0 !important'
  },
}

export default style
