import React, { ReactNode } from 'react'
import { Dialog, Button, ButtonProps, ButtonCategory } from 'components'

interface VerifyButtonProps extends ButtonProps {
  title: string
  description: string
  confirmText: string
  content?: ReactNode
  confirmButtonCategory?: ButtonCategory
  onConfirm?: () => void
  children?: any
}


const VerifyButton = ({ ...props }: VerifyButtonProps) => {
  const [alertOpen, setAlertOpen] = React.useState(false)

  const handleOpen = () => {
    setAlertOpen(true)
  }

  const handleClose = () => {
    setAlertOpen(false)
  }

  const confirmDelete = () => {
    handleClose()
    if (props.onConfirm != null) props.onConfirm()
  }

  return (
    <>
      <Button {...props} onClick={handleOpen}>
        {props.children}
      </Button>

      <Dialog open={alertOpen}
        title={props.title}
        description={props.description}
        content={props.content}
        confirmText={props.confirmText}
        confirmButtonCategory={props.confirmButtonCategory}
        onConfirm={confirmDelete}
        onClose={handleClose}
      />
    </>
  )
}

export default VerifyButton
