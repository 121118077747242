import { FontWeight, Color } from 'theme/style'

const style = {
  transitionContainer: {
    '& p': {
      fontSize: '1.5rem',
      fontWeight: FontWeight.Medium
    }
  },
  dayLabel: {
    color: Color.Primary,
    textTransform: 'uppercase',
    fontSize: '1.05rem',
    fontWeight: FontWeight.Medium
  }
}

export default style
