import spacing from 'theme/config/spacing'

const style = {
  paper: {
    borderRadius: '1.6rem',
    padding: spacing(1),
    boxShadow: '12px 12px 24px 0 rgba(0,0,0,0.12)'
  }
}

export default style
