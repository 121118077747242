import React from 'react'
import { Spinner } from 'components'
import { Color } from 'theme/style'

interface AsyncImageProps {
  src: string
  alt?: string
  className?: string
}

const AsyncImage = ({ ...props }: AsyncImageProps) => {
  const [loadedSrc, setLoadedSrc] = React.useState<string | null>(null)

  React.useEffect(() => {
    setLoadedSrc(null)

    const handleLoad = () => {
      setLoadedSrc(props.src)
    }
    const image = new Image()
    image.addEventListener('load', handleLoad)
    image.src = props.src
    return () => {
      image.removeEventListener('load', handleLoad)
    }
  }, [props.src])

  if (loadedSrc === props.src) {
    return <img className={props.className} src={props.src} alt={props.alt} />
  }

  return <div className={props.className}>
    <Spinner type={'small'} spinnerColor={Color.TextSecondary} />
  </div>
}

export default AsyncImage
