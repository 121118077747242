import { Card, makeStyles } from '@material-ui/core'
import {
  updatePageImage, UpdatePageImageRequestParams
} from 'app/api'
import { MediaLibraryFile } from 'app/entities/types'
import { toast } from 'app/utils'
import { MediaLibraryFolderValue } from 'app/values'
import clsx from 'clsx'
import { CardContent, CardField, CardHeader, PageEntityDetailsRefreshableProps } from 'components'
import { useLocalization } from 'components/methods'
import clone from 'lodash/cloneDeep'
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    width: '100%',
    height: '300px',
    border: 'none',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
}))

interface CardCoverImageProps extends PageEntityDetailsRefreshableProps {
  pageId: number,
  image?: MediaLibraryFile | null,
  title?: string,
  mediaLibraryFolder?: MediaLibraryFolderValue,
  handleImageSelectedCallback?: (item: MediaLibraryFile | null) => void,
  handleImageConfirmedCallback?: (item: MediaLibraryFile | null) => void
}
const CardCoverImage = ({ ...props }: CardCoverImageProps) => {
  const classes = useStyles()
  const { t } = useLocalization()

  const [isLoading, setIsLoading] = useState(true)

  const [selectedImage, setSelectedImage] = useState<MediaLibraryFile | null>(null)

  useEffect(() => {
    const img = clone(props.image)
    setSelectedImage(img ?? null)
  }, [])

  useEffect(() => {
    const initialized = props.pageId != null
    setIsLoading(!initialized)
  }, [props.pageId])

  function updateImageValue(name: string, value: any) {
    setSelectedImage(value)
    updateObject(value)
  }

  function removeImageValue() {
    setSelectedImage(null)
    updateObject(null)
  }

  function updateObject(selectedItem: MediaLibraryFile | null) {
    const encode = (): UpdatePageImageRequestParams => {
      return {
        id: props.pageId,
        mediaFileId: selectedItem?.id ?? null
      }
    }

    updatePageImage(encode(), {
      response(data) {
        toast.success(t('alert.page-data.cover-image.update'))
        if (props.refreshObject != null) props.refreshObject()
      },
      error(error, message) {
        toast.error(message)
      }
    })
  }

  return (
    <>
      <Card className={clsx(classes.root)}>
        <CardHeader title={props.title ?? t('tab.page-data.cover-image.title')} />
        <CardContent isLoading={isLoading}>
          <CardField type={'image'} name={'cover-image'}
            label={t('tab.page-data.cover-image.image.label')}
            mediaLibraryFolder={props.mediaLibraryFolder}
            value={selectedImage?.path ?? ''}
            onConfirm={updateImageValue} onRemove={removeImageValue}
          />
        </CardContent>
      </Card>
    </>
  )
}

export default CardCoverImage