import { Grid } from '@material-ui/core';
import { Language, Page } from 'app/entities/types';
import { PageSections } from 'components';
import React from 'react';

type ContentProps = {
  object: Page
  language: Language
  pageId: number
}
const Content = ({ ...props }: ContentProps) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <PageSections object={props.object} pageId={props.pageId} language={props.language} />
      </Grid>
    </Grid>
  )
}

export default Content
