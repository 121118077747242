import { Value } from 'theme/style'

const style = {
  root: {
    borderRadius: Value.BorderRadius,
    minHeight: '0px'
  }
}

export default style
