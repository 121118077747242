import spacing from 'theme/config/spacing'
import { Value } from 'theme/style'

const style = {
  root: {
    padding: spacing(2),
    borderRadius: Value.BorderRadius,
    height: '100%'
  },
};

export default style