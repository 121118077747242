import { useSnackbar, OptionsObject, WithSnackbarProps } from 'notistack'
import React from 'react'

interface IProps {
  setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void
}

const InnerSnackbarUtilsConfigurator: React.FC<IProps> = (props: IProps) => {
  props.setUseSnackbarRef(useSnackbar())
  return null
}

let useSnackbarRef: WithSnackbarProps
const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
  useSnackbarRef = useSnackbarRefProp
}

export const SnackbarUtilsConfigurator = () => {
  return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
}

const toast = {
  success(msg: string) {
    this.toast(msg, {
      variant: 'success',
      autoHideDuration: 2000,
    })
  },
  warning(msg: string) {
    this.toast(msg, {
      variant: 'warning',
      autoHideDuration: 5000
    })
  },
  info(msg: string) {
    this.toast(msg, {
      variant: 'info',
      autoHideDuration: 5000
    })
  },
  error(msg: string) {
    this.toast(msg, {
      variant: 'error',
      autoHideDuration: 5000
    })
  },

  toast(msg: string, options: OptionsObject) {
    useSnackbarRef.enqueueSnackbar(msg, options)
  }
}

export default toast