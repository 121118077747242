import { Value, Color } from 'theme/style'

const style = {
  root: {
    borderRadius: Value.BorderRadius_Button,
    transition: 'all 0.2s',
  },
  contained: {
    padding: '10px 15px',
    textTransform: 'uppercase',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  containedPrimary: {
    color: Color.White
  },
  text: {
    padding: '6px 12px'
  }
};

export default style