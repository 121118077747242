import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable } from 'app/api/types'


enum APIEndpoint {
  GetAllCommonStrings = 'api/commonStrings',
  GetCommonString = 'api/commonStrings/getById',
  CreateCommonString = 'api/commonStrings/create',
  UpdateCommonStrings = 'api/commonStrings/update',
  DeleteCommonString = 'api/commonStrings/delete',
}


//Retrieve information about all the common contents.

export interface GetAllCommonStringsRequestParams extends APIPaginatable, APISortable {
}


export interface GetAllCommonStringsResponse extends APIPaginatableResponse {
  commonStrings: {
    id: number
    key: string
    translations: {
      id: number
      value: string
      languageId: number
    }[],
    tags: {
      id: number
      keyword: string
      name: string
    }[]
  }[]
}

enum GetAllCommonStringsResponseError {
}

export async function getAllCommonStrings(params: GetAllCommonStringsRequestParams, output?: RequestOutput<GetAllCommonStringsResponse, GetAllCommonStringsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllCommonStrings,
    params: params,
    output: output,
  })
}


//Retrieve information about a specific common content.

type GetCommonStringRequestParams = {
  id: number
}

export type GetCommonStringResponse = {
  commonStrings: {
    id: number
    key: string
    translations: {
      id: number
      value: string
      languageId: number
    }[]
    tags: {
      id: number
      keyword: string
      name: string
    }[]
  }
}

enum GetCommonStringResponseError {
}

export async function getCommonString(params: GetCommonStringRequestParams, output?: RequestOutput<GetCommonStringResponse, GetCommonStringResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetCommonString,
    params: params,
    output: output,
  })
}


//Retrieve the authentication token.
//Used when logging in and authorizing the client to make future API calls.

export type CreateCommonStringRequestParams = {
  key: string
  translations: {
    value: string
    languageId: number
  }[]
  tags: number[]
}

type CreateCommonStringResponse = {
  commonString: {
    id: number
    key: string
    translations: {
      id: number
      value: string
      languageId: number
    }[]
    tags: {
      id: number
      name: string
    }[]
  }
}

enum CreateCommonStringReponseError {
}

export async function createCommonString(params: CreateCommonStringRequestParams, output?: RequestOutput<CreateCommonStringResponse, CreateCommonStringReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateCommonString,
    params: params,
    output: output,
  })
}


//Retrieve the authentication token.
//Used when logging in and authorizing the client to make future API calls.

export type UpdateCommonStringsRequestParams = {
  commonStrings?: {
    id: number
    key: string
    translations: {
      value: string
      languageId: number
    }[]
    tags: number[]
  }[]
}

type UpdateCommonStringsResponse = {
}

enum UpdateCommonStringsReponseError {
}

export async function updateCommonStrings(params: UpdateCommonStringsRequestParams, output?: RequestOutput<UpdateCommonStringsResponse, UpdateCommonStringsReponseError>) {
  if (params.commonStrings?.length === 0) {
    return
  }

  executeRequest({
    endpoint: APIEndpoint.UpdateCommonStrings,
    params: params,
    output: output,
  })
}



//Retrieve the authentication token.
//Used when logging in and authorizing the client to make future API calls.

export type DeleteCommonStringRequestParams = {
  id: number
}

type DeleteCommonStringResponse = {
}

enum DeleteCommonStringReponseError {
}

export async function deleteCommonString(params: DeleteCommonStringRequestParams, output?: RequestOutput<DeleteCommonStringResponse, DeleteCommonStringReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteCommonString,
    params: params,
    output: output,
  })
}