import React from 'react'
import { makeStyles } from '@material-ui/core'
import { User, Customer } from 'app/entities/types'
import { UserAvatar, UserAvatarSize } from 'components'

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(1)
  }
}))

interface UserInfoProps {
  user: User | Customer
  avatarSize?: UserAvatarSize
}

const UserInfo = ({ ...props }: UserInfoProps) => {
  const classes = useStyles()
  return (
    <div className={classes.avatarContainer} >
      <UserAvatar user={props.user} avatarSize={props.avatarSize} className={classes.avatar} />
      {props.user.firstname} {props.user.lastname}
    </div >
  )
}

export default UserInfo
