import PageIcon from '@material-ui/icons/Description'
import CommonTextIcon from '@material-ui/icons/TextFields'
// import ImportContactsIcon from '@material-ui/icons/ImportContacts'
// import AnnouncementIcon from '@material-ui/icons/Announcement'
// import BookIcon from '@material-ui/icons/Book'
// import FastfoodIcon from '@material-ui/icons/Fastfood'
import UsersIcon from '@material-ui/icons/PeopleAlt'
import CodeIcon from '@material-ui/icons/Code'
// import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
// import BusinessIcon from '@material-ui/icons/Business'
// import CopyrightIcon from '@material-ui/icons/Copyright'
// import StoreIcon from '@material-ui/icons/Store'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'
import BrokenImageIcon from '@material-ui/icons/BrokenImage'
import FaceIcon from '@material-ui/icons/Face'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'

const items = [
  {
    title: 'sidebar.title.website', pages: [
      { title: 'sidebar.item.pages', icon: PageIcon, href: '/website/pages' },
      { title: 'sidebar.item.dictionary', icon: CommonTextIcon, href: '/website/dictionary' },
      { title: 'sidebar.item.media-library', icon: PhotoLibraryIcon, href: '/website/media-library', },
    ]
  },

  {
    title: 'sidebar.title.insurance', pages: [
      { title: 'sidebar.item.clients', icon: FaceIcon, href: '/insurance/clients' },
      { title: 'sidebar.item.policies', icon: VerifiedUserIcon, href: '/insurance/policies' },
      { title: 'sidebar.item.claims', icon: BrokenImageIcon, href: '/insurance/claims' },
    ]
  },

  {
    title: 'sidebar.title.admin', pages: [
      { title: 'sidebar.item.users', icon: UsersIcon, href: '/admin/users' },
      { title: 'sidebar.item.dev', icon: CodeIcon, href: '/dev/overview' },
    ]
  },
]

export default items