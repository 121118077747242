import typography from 'theme/config/typography'
import { Color } from 'theme/style'

const style = {
  root: {
    ...typography.h6,
  },
  colorDefault: {
    backgroundColor: Color.Avatar,
    color: Color.AvatarText,
  }
}

export default style