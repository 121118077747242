import { Card, makeStyles } from '@material-ui/core'
import { Customer } from 'app/entities/types'
import { PageEntityDetailsRefreshableProps, UserAvatar, CardContent, CardHeader, CardField } from 'components'
import { useLocalization } from 'components/methods'
import React, { useState, useEffect } from 'react'

const useStyles = makeStyles((theme) => ({
	avatarContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		marginBottom: theme.spacing(3)
	},
}))

enum FieldName {
	FirstName = 'firstname',
	LastName = 'lastname',
	Username = 'username',
	Email = 'email',
	Avatar = 'avatar',
}

type DataProps = {
	id: number
	[FieldName.FirstName]: string,
	[FieldName.LastName]: string,
	[FieldName.Username]: string,
	[FieldName.Email]: string,
	[FieldName.Avatar]: string
}

interface CardShopCustomerProps extends PageEntityDetailsRefreshableProps {
	object: Customer | null,
}

const CardShopCustomer = ({ ...props }: CardShopCustomerProps) => {
	const classes = useStyles()
	const { t } = useLocalization()
	const [isLoading, setIsLoading] = useState(true)



	//OBJECT

	const [data] = useState<DataProps>({
		id: props.object?.id ?? 0,
		[FieldName.FirstName]: props.object?.firstname ?? '',
		[FieldName.LastName]: props.object?.lastname ?? '',
		[FieldName.Username]: props.object?.username ?? '',
		[FieldName.Email]: props.object?.email ?? '',
		[FieldName.Avatar]: props.object?.avatar ?? ''
	})
	// const [currentData, setCurrentData] = useState(data)
	// const [isUpdatingCurrentData, setIsUpdatingCurrentData] = useState(false)

	// function updateData(data: DataProps) {
	// 	setIsUpdatingCurrentData(true)
	// 	setData(data)
	// 	setCurrentData(data)
	// }

	// useEffect(() => {
	// 	setIsUpdatingCurrentData(false)
	// }, [currentData])

	useEffect(() => {
		const initialized = props.object != null
		setIsLoading(!initialized)
	}, [props.object])


	//RENDER

	return (
		<>
			<Card>
				<CardHeader title={t('component.CardShopCustomer.title')} />
				<CardContent isLoading={isLoading} >
					<div className={classes.avatarContainer}>
						<UserAvatar user={props.object} avatarSize={'huge'} />
					</div>

					<CardField disabled type={'text'} name={FieldName.FirstName}
						label={t('component.CardShopCustomer.firstname.label')} placeholder={t('component.CardShopCustomer.firstname.placeholder')}
						value={data.firstname}
					/>

					<CardField disabled type={'text'} name={FieldName.LastName}
						label={t('component.CardShopCustomer.lastname.label')} placeholder={t('component.CardShopCustomer.lastname.placeholder')}
						value={data.lastname}
					/>

					<CardField disabled type={'text'} name={FieldName.Username}
						label={t('component.CardShopCustomer.username.label')} placeholder={'component.CardShopCustomer.username.placeholder'}
						value={data.username}
					/>

					<CardField disabled type={'text'} name={FieldName.Email}
						label={t('component.CardShopCustomer.email.label')} placeholder={'component.CardShopCustomer.email.placeholder'}
						value={data.email}
					/>
				</CardContent>
			</Card>
		</>
	)
}

export default CardShopCustomer