import React from 'react'
import { Page } from 'app/entities/types'
import { CardCoverImage, PageEntityDetailsRefreshableProps } from 'components';
import { MediaLibraryFolderValue } from 'app/values';

interface PageCoverImageProps extends PageEntityDetailsRefreshableProps {
	object: Page,
	pageId: number
	mediaLibraryFolder?: MediaLibraryFolderValue
}

const PageCoverImage = ({ ...props }: PageCoverImageProps) => {
	return (
		<CardCoverImage pageId={props.pageId} image={props.object.coverImage} mediaLibraryFolder={props.mediaLibraryFolder ?? MediaLibraryFolderValue.General} refreshObject={props.refreshObject} />
	)
}

export default PageCoverImage