import { useState, ReactNode } from 'react'
import clsx from 'clsx';
import { makeStyles, Menu, MenuItem, Typography, IconButton } from '@material-ui/core'
import { FontWeight, Color } from 'theme/style'
import { PageTabProps } from 'components'
import { useRouter } from 'app/utils'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useLocalization } from 'components/methods'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
  header: {
    width: 'calc(100% - 280px)',
    backgroundColor: Color.White,
    height: '60px',
    flex: '0 0 60px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
    alignItems: 'center',
    boxShadow: '0 8px 12px 0 rgba(0,0,0,0.08)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  leftContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      marginLeft: '40px',
    },
  },
  rightContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: '0 0 auto',
    alignItems: 'center'
  },
  backArrowContainer: {
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    borderRadius: '20px',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: Color.Background,
    }
  },
  backArrow: {
    height: '24px',
    width: '24px',
    color: Color.TextPrimary,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  title: {
  },
  subtitle: {
    fontWeight: FontWeight.Regular,
    color: theme.palette.text.primary
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    '&>*': {
      marginLeft: theme.spacing(1)
    }
  },
  tabsContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    flex: 1,
    position: 'relative',
    // [theme.breakpoints.down('sm')]: {
    //   display: 'none'
    // },
  },
  actionsContainer: {
    display: 'block',
    flex: '0 0 auto',
    // [theme.breakpoints.down('sm')]: {
    //   display: 'none'
    // },
  },
  secondaryActionsContainer: {
    paddingLeft: theme.spacing(1),
    marginLeft: theme.spacing(2),
    borderLeft: '2px solid transparent',
    borderLeftColor: Color.Background
  },
  titleSection: {
    ...theme.typography.body1,
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    color: Color.TextSecondary,
    fontWeight: FontWeight.Medium,
    top: '2px'
  }
}))


type PageHeaderProps = {
  className?: string
  title: string

  actions?: ReactNode
  secondaryActions?: ReactNode[]
  supportActions?: ReactNode
  previousRoute?: string

  tabs?: PageTabProps[]
  selectedTab?: string
  defaultTab?: string
  updateTab?: (tab: PageTabProps) => void
  disableTabLink?: boolean
}
const PageHeader = ({ ...props }: PageHeaderProps) => {
  const classes = useStyles()
  const router = useRouter()
  const { t } = useLocalization()



  //ACTIONS

  const [anchorSecondaryActionsEl, setAnchorSecondaryActionsEl] = useState<null | HTMLElement>(null)
  const secondaryActionsAreOpen = Boolean(anchorSecondaryActionsEl)

  const openSecondaryActions = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorSecondaryActionsEl(event.currentTarget)
  }

  const closeSecondaryActions = () => {
    setAnchorSecondaryActionsEl(null)
  }



  //TABS

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const tabsOpen = Boolean(anchorEl)

  function selectedTabLabel(): string {
    if (getTabs().length === 1) {
      return getTabs()[0].label
    }

    const currentTab = getTabs().filter(t => t.value === props.selectedTab)
    if (currentTab.length === 1) return currentTab[0].label ?? ''
    return ''
  }

  function handleTabChange(event: any, value: string) {
    if (props.tabs == null) return

    //Check if the tab is a valid one.
    const tab = props.tabs.find(t => t.key === value)
    if (tab == null) return

    if (props.updateTab != null) props.updateTab(tab)

    //Updates the route.
    if (props.disableTabLink !== true) router.history.push(tab.key)
  }

  const handleTabClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleTabsClose = () => {
    setAnchorEl(null)
  }

  function getTabs(): { key: string, label: string, value: any }[] {
    let tabs: any = []

    // if (props.tabs == null) {
    //   tabs.push({ key: '1', label: t('page.tab.general'), value: 'main' })
    // }

    if (props.tabs != null) {
      props.tabs!.forEach((tab, i) => (
        tabs.push({ key: i, label: tab.label ?? t('page.tab.' + tab.key), value: tab.key })
      ))
    }

    return tabs
  }


  //RENDER

  return (
    <div className={clsx(props.className, classes.header)} >
      <div className={classes.leftContent}>
        {props.previousRoute != null &&
          <div className={classes.backArrowContainer} onClick={() => router.history.push(props.previousRoute ?? '')}>
            <ArrowBackIcon className={classes.backArrow} />
          </div>
        }
        <div className={classes.titleContainer}>
          <Typography className={classes.title} component="h1" variant="h3">
            {props.title}
          </Typography>
          {getTabs().length > 1 &&
            <div className={classes.tabsContainer}>
              <div className={classes.titleSection} onClick={handleTabClick}>{selectedTabLabel()}<ExpandMoreIcon /></div>
              <Menu anchorEl={anchorEl} open={tabsOpen} onClick={handleTabsClose} onClose={handleTabsClose}>
                {getTabs().map((data) => (
                  data.value !== '' && <MenuItem key={data.key} onClick={e => { handleTabClick(e); handleTabChange(e, data.value) }}>{data.label}</MenuItem>
                ))}
              </Menu>
            </div>
          }
        </div>
      </div>

      <div className={classes.rightContent}>
        <div className={classes.actionsContainer}>
          {props.actions}
        </div>
        {(props.supportActions != null || props.secondaryActions != null) &&
          <div className={classes.secondaryActionsContainer}>
            {props.supportActions != null &&
              <>
                {props.supportActions}
              </>
            }
            {props.secondaryActions != null &&
              <>
                <IconButton onClick={openSecondaryActions}><MoreVertIcon /></IconButton>
                <Menu anchorEl={anchorSecondaryActionsEl} open={secondaryActionsAreOpen} onClick={closeSecondaryActions} onClose={closeSecondaryActions}>
                  {props.secondaryActions?.map(e => e)}
                </Menu>
              </>
            }
          </div>
        }
      </div>
    </div>
  )
}

export default PageHeader
