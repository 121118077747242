import palette from 'theme/config/palette';

const style = {
  root: {
    color: palette.icon,
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)'
    }
  },
  label: {
    width: 'auto'
  }
}

export default style
