import { Card as MaterialCard, CardProps as MaterialCardProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
// import { shouldSave } from 'components/methods'
// import { Button } from 'components';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}))


interface CardProps extends MaterialCardProps {
  data?: any
  currentData?: any
  errorsConditions?: any
  saveFunction?: () => void
}

const Card = ({ ...props }: CardProps) => {
  const classes = useStyles()

  // function triggerSave() {
  //   if (shouldSave({ fieldParameters: props.errorsConditions, data: props.data, currentData: props.currentData }) === false) return
  //   if (props.saveFunction != null) props.saveFunction()
  // }

  return (
    <MaterialCard className={clsx(classes.root)} >
      {props.children}
      {/* <Button onClick={triggerSave}>Save</Button> */}
    </MaterialCard>
  )
}

export default Card
