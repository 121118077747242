import React, { useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import { Paper, Typography, makeStyles } from '@material-ui/core'
import { Toolbar } from '@material-ui/core'
import useRouter from 'app/utils/useRouter'
import { Navigation, UserAvatar } from 'components'
import navItems from './items'
import { Color, FontSize, FontWeight } from 'theme/style'
import { useSelector } from 'react-redux';
import { RootState } from 'app/session/store'
import { fade } from '@material-ui/core/styles'
import { useLocalization } from 'components/methods'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    background: Color.Sidebar,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      left: '-100vw',
      top: 0,
      transition: 'left 0.2s ease-in-out'
    },
  },
  rootOpen: {
    left: 0
  },
  mainNav: {
    display: 'flex',
    height: 'calc(100vh - 80px - 20px)',
    flexDirection: 'column',
  },
  heading: {
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    justifyContent: 'left',
    marginTop: theme.spacing(1),
    zIndex: 999,
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '100%',
      left: 0,
      height: '40px',
      width: '100%',
      background: 'linear-gradient(' + fade(Color.Sidebar, 1) + ' 0%, ' + fade(Color.Sidebar, 0) + ' 100%)',
    },
    '& a': {
      width: '100%',
      height: '38px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      '& img': {
        width: '100%',
        height: '100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      }
    }
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    paddingTop: '30px',
    width: '100%',
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  name: {
    marginTop: theme.spacing(1)
  },
  navigation: {
    marginTop: 0,
  },
  userBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: Color.Sidebar,
    width: '100%',
    flex: '0 0 80px',
    maxHeight: '80px',
    position: 'relative',
    bottom: '20px',
    zIndex: 999,
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '-40px',
      left: 0,
      height: '40px',
      width: '100%',
      background: 'linear-gradient(' + fade(Color.Sidebar, 0) + ' 0%, ' + fade(Color.Sidebar, 1) + ' 100%)',
    },
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  avatar: {
  },
  avatarDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    marginLeft: theme.spacing(1),
    color: theme.palette.common.white,
    '& h4': {
      color: 'white',
    },
    '& a': {
      color: 'rgba(255, 255, 255, 0.4)',
      paddingTop: '5px',
      display: 'block'
    }
  },
  logoutText: {
    '& a': {
      color: Color.Error,
      textTransform: 'uppercase',
      fontSize: FontSize.BodyMicro,
      fontWeight: FontWeight.Medium,
      letterSpacing: '1px'
    }
  }
}));


type SidebarProps = {
  openMobile?: boolean
  onMobileClose?: any
  className: any
  onClick?: () => void
  onItemClick?: () => void
}
const Sidebar = ({ openMobile, onMobileClose, className, onClick, onItemClick, ...rest }: SidebarProps) => {
  const session = useSelector((state: RootState) => state.session)
  const { t } = useLocalization()
  const classes = useStyles();
  const router = useRouter();

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
  }, [openMobile, router.location.pathname]);

  return (
    <>
      <Paper className={openMobile ? clsx(classes.root, classes.rootOpen, className) : clsx(classes.root, className)} elevation={1} square {...rest}>
        <div className={classes.mainNav}>
          <Toolbar className={classes.heading}>
            <RouterLink to="/dashboard">
              <img alt="Logo" src={'/images/common/logo.svg'} />
            </RouterLink>
          </Toolbar>
          <div className={classes.content}>
            <nav className={classes.navigation}>
              {
                navItems.map(item => (
                  <Navigation key={item.title} component="div" pages={item.pages} title={item.title} onClick={onClick} onItemClick={onItemClick} />
                ))
              }
            </nav>
          </div>
        </div>
        <Toolbar className={classes.userBar}>
          <div className={classes.avatarContainer}>
            <UserAvatar user={session.user} className={classes.avatar} avatarSize={'medium'} color={true} />
            <div className={classes.avatarDetails}>
              <Typography variant="h4">{session.user.firstname} {session.user.lastname}</Typography>
              <Typography className={classes.logoutText}><RouterLink to="/auth/logout">{t('common.logout')}</RouterLink></Typography>
            </div>
          </div>
        </Toolbar>
      </Paper>
    </>
  );
};

export default Sidebar
