import { Color, Value } from 'theme/style'

const style = {
  root: {
  },
  endAdornment: {
    '& svg': {
      fontSize: '2.2rem',
      color: Color.TextSecondary
    }
  },
  listbox: {
    padding: '8px'
  },
  option: {
    borderRadius: Value.BorderRadius,
  }
};

export default style