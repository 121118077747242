import { jsonToCSV } from 'react-papaparse'
import { saveAs } from 'file-saver'
import { toast } from 'app/utils'

export type ExportData<T> = {
	key: string,
	value: (item: T) => string
}

type ExportCallback = {
	success?: (exportedData: any) => void,
	error?: () => void
}
export function processDataForExport(instructions: ExportData<any>[], filename: string, data: any[], exportingState: ((state: boolean) => void), callback?: ExportCallback) {
	let dataToExport: any = prepareForExport(data)

	const csv = jsonToCSV(dataToExport)
	const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
	const file = filename + '.csv'

	saveAs(csvData, file)
	if (callback?.success != null) {
		toast.success('Lista esportata con successo')
		callback.success(dataToExport)
	}
	exportingState(false)

	function prepareForExport(items: any[]) {
		const temp: any = []
		const exportInstr = instructions
		items.forEach(i => {
			let rowData: any = {}
			exportInstr.forEach(exp => {
				rowData[exp.key] = exp.value(i)
			})
			temp.push(rowData)
		})
		return temp
	}
}