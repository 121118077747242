import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import { FontWeight, Color } from 'theme/style'
import { useLocalization } from 'components/methods'
import { randomEmoji } from './methods'

const useStyles = makeStyles((theme) => ({
  noResults: {
    width: '100%',
    height: '100%',
    minHeight: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: Color.TextSecondary,
  },
  noResultsEmoji: {
    display: 'block',
    fontSize: '5rem',
    fontWeight: FontWeight.Light,
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    color: Color.TextSecondary,
    opacity: 0.5,
  },
  noResultsText: {
    fontWeight: FontWeight.Medium,
    opacity: 0.7,
    textAlign: 'center',
    color: Color.TextSecondary,
  },
}))

interface EmojiResultsProps {
  emoji?: string
  emojiStyle: 'happy' | 'angry'
}

const EmojiResults = ({ ...props }: EmojiResultsProps) => {
  const classes = useStyles()
  const { t } = useLocalization()

  return <>
    <div className={classes.noResults}>
      <Typography className={classes.noResultsEmoji}>{props.emoji ?? randomEmoji(props.emojiStyle ?? 'angry')}</Typography>
      <Typography className={classes.noResultsText}>{t('component.CardListing.content.no-results')}</Typography>
    </div>
  </>
}

export default EmojiResults
