import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'

enum APIEndpoint {
  GetAllProducts = 'api/products',
  GetProductById = 'api/products/getById',
  CreateProduct = 'api/products/create',
  UpdateProduct = 'api/products/update',
  DeleteProduct = 'api/products/delete',
  UpdateProductStatus = 'api/products/updatePublished',
  GetProductTranslation = 'api/products/getTranslation',
  UpdateProductTranslation = 'api/products/updateTranslation',
  GetProductPrices = 'api/products/getPrices',
  UpdateProductPrices = 'api/products/updatePrices',
  GetProductStock = 'api/products/getStock',
  UpdateProductStock = 'api/products/updateStock',
  GetProductDimensions = 'api/products/getDimensions',
  UpdateProductDimensions = 'api/products/updateDimensions',
  GetProductGallery = 'api/products/getGallery',
  AddProductGalleryImage = 'api/products/addGalleryImage',
  RemoveProductGalleryImage = 'api/products/removeGalleryImage',
  GetAllProductArtists = 'api/products/getArtists',
  AddArtistToProduct = 'api/products/addArtist',
  RemoveArtistFromProduct = 'api/products/removeArtist',
  GetProductPickupAddress = 'api/products/getPickupAddress',
  AddProductPickupAddress = 'api/products/addPickupAddress',
  RemoveProductPickupAddress = 'api/products/removePickupAddress',
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.




export interface GetAllProductsRequestParams extends APIPaginatable, APISortable, APISearchable {
  languageId: number
}

export interface GetAllProductsResponse extends APIPaginatableResponse {
  products: {
    id: number
    keyword: string
    published: boolean | null
    featured?: boolean | null
    featuredOrder?: number
    pageId: number
    parentId: number | null
    translation: {
      name: string
      description?: string
    }[]
    taxonomies: {
      id: number
      name: string
      slug: string
    }[]
    sku: string
    onEnquiry: boolean | null
    year: number
    prices: {
      id: number
      currency: {
        id: number
        name: string
        code: string
        htmlSymbol: string
      }
      price: number
      sellingPrice: number
      wholesalePrice: number
      unitPrice: number
      unityCommonStringId: number
    }[]
    dimensions: {
      width: number
      height: number
      depth: number
      weight: number
    },
    stock: {
      quantity: number
      outOfStock: boolean
      maximalQuantity: number
      minimalQuantity: number
    }
  }[]
}

enum GetAllProductsResponseError {
}

export async function getAllProducts(params: GetAllProductsRequestParams, output?: RequestOutput<GetAllProductsResponse, GetAllProductsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllProducts,
    params: params,
    output: output,
  })
}



export type GetProductRequestParams = {
  id: number
  languageId: number
}
export type GetProductResponse = {
  product: {
    id: number
    keyword: string
    published: boolean | null
    featured?: boolean | null
    featuredOrder?: number
    pageId: number
    parentId: number | null
    mainImage: {
      id: number
      type: string
      folder: number
      filename: string
      basepath: string
      path: string
    },
    translation: {
      name: string
      description?: string
    }[]
    taxonomies: {
      id: number
      name: string
      slug: string
    }[]
    sku: string
    onEnquiry: boolean | null
    year: number
    prices: {
      id: number
      currency: {
        id: number
        name: string
        code: string
        htmlSymbol: string
      }
      price: number
      sellingPrice: number
      wholesalePrice: number
      unitPrice: number
      unityCommonStringId: number
    }[]
    dimensions: {
      width: number
      height: number
      depth: number
      weight: number
    },
    stock: {
      quantity: number
      outOfStock: boolean
      maximalQuantity: number
      minimalQuantity: number
    }
  }
}

enum GetProductResponseError {
}

export async function getProduct(params: GetProductRequestParams, output?: RequestOutput<GetProductResponse, GetProductResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetProductById,
    params: params,
    output: output,
  })
}



export type CreateProductRequestParams = {
  parentId: number | null
  tags: number[]
}

type CreateProductResponse = {
  product: {
    id: number
    name: string
  }
}

enum CreateProductReponseError {
}

export async function createProduct(params: CreateProductRequestParams, output?: RequestOutput<CreateProductResponse, CreateProductReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateProduct,
    params: params,
    output: output,
  })
}



export type UpdateProductRequestParams = {
  id: number
  keyword: string
  parentId: number | null
  sku: string
  onEnquiry: boolean | null
  featured?: boolean | null
  featuredOrder?: number
  year: number
}

type UpdateProductResponse = {
}

enum UpdateProductReponseError {
}

export async function updateProduct(params: UpdateProductRequestParams, output?: RequestOutput<UpdateProductResponse, UpdateProductReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateProduct,
    params: params,
    output: output,
  })
}



export type DeleteProductRequestParams = {
  id: number
}

type DeleteProductResponse = {
}

enum DeleteProductReponseError {
}

export async function deleteProduct(params: DeleteProductRequestParams, output?: RequestOutput<DeleteProductResponse, DeleteProductReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteProduct,
    params: params,
    output: output,
  })
}



export type UpdateProductStatusRequestParams = {
  id: number
  published: boolean,
}

type UpdateProductStatusResponse = {
}

enum UpdateProductStatusReponseError {
}

export async function updateProductStatus(params: UpdateProductStatusRequestParams, output?: RequestOutput<UpdateProductStatusResponse, UpdateProductStatusReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateProductStatus,
    params: params,
    output: output,
  })
}



export type GetProductTranslationRequestParams = {
  productId: number
  languageId: number
}

export type GetProductTranslationResponse = {
  productTranslation: {
    id: number,
    name: string,
    slug: string,
    description: string,
    ingredients: string,
    benefits: string,
    deepening: string,
    languageId: number
  }
}

enum GetProductTranslationResponseError {
}

export async function getProductTranslation(params: GetProductTranslationRequestParams, output?: RequestOutput<GetProductTranslationResponse, GetProductTranslationResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetProductTranslation,
    params: params,
    output: output,
  })
}



export type UpdateProductTranslationRequestParams = {
  id: number,
  name?: string | null,
  slug?: string | null,
  description?: string | null,
  ingredients?: string | null,
  benefits?: string | null,
  deepening?: string | null,
}

type UpdateProductTranslationResponse = {
}

enum UpdateProductTranslationReponseError {
}

export async function updateProductTranslation(params: UpdateProductTranslationRequestParams, output?: RequestOutput<UpdateProductTranslationResponse, UpdateProductTranslationReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateProductTranslation,
    params: params,
    output: output,
  })
}



export type GetProductPricesRequestParams = {
  productId: number
}

export type GetProductPricesResponse = {
  productPrices: {
    id: number,
    name: string,
    slug: string,
    description: string,
    meta: {
      desktop: {
        metaTitleDesktop: string | null
        metaDescriptionDesktop: string | null,
      },
      mobile: {
        metaTitleMobile: string | null,
        metaDescriptionMobile: string | null,
      }
    }
    languageId: number
  }
}

enum GetProductPricesResponseError {
}

export async function getProductPrices(params: GetProductPricesRequestParams, output?: RequestOutput<GetProductPricesResponse, GetProductPricesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetProductPrices,
    params: params,
    output: output,
  })
}



export type UpdateProductPricesRequestParams = {
  productId: number,
  currencyId: number
  price?: number | null,
  sellingPrice?: number | null,
  wholesalePrice?: number | null,
  unitPrice?: number | null
  unityCommonStringId?: number | null
}

type UpdateProductPricesResponse = {
}

enum UpdateProductPricesReponseError {
}

export async function updateProductPrices(params: UpdateProductPricesRequestParams, output?: RequestOutput<UpdateProductPricesResponse, UpdateProductPricesReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateProductPrices,
    params: params,
    output: output,
  })
}




export type GetProductStockRequestParams = {
  productId: number
}

export type GetProductStockResponse = {
  productStock: {
    id: number,
    name: string,
    slug: string,
    description: string,
    meta: {
      desktop: {
        metaTitleDesktop: string | null
        metaDescriptionDesktop: string | null,
      },
      mobile: {
        metaTitleMobile: string | null,
        metaDescriptionMobile: string | null,
      }
    }
    languageId: number
  }
}

enum GetProductStockResponseError {
}

export async function getProductStock(params: GetProductStockRequestParams, output?: RequestOutput<GetProductStockResponse, GetProductStockResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetProductStock,
    params: params,
    output: output,
  })
}



export type UpdateProductStockRequestParams = {
  productId: number,
  quantity: number,
  minimalQuantity: number,
  maximalQuantity: number,
  outOfStock: boolean
}

type UpdateProductStockResponse = {
}

enum UpdateProductStockReponseError {
}

export async function updateProductStock(params: UpdateProductStockRequestParams, output?: RequestOutput<UpdateProductStockResponse, UpdateProductStockReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateProductStock,
    params: params,
    output: output,
  })
}




export type GetProductDimensionsRequestParams = {
  productId: number
}

export type GetProductDimensionsResponse = {
  productDimensions: {
    id: number,
    name: string,
    slug: string,
    description: string,
    meta: {
      desktop: {
        metaTitleDesktop: string | null
        metaDescriptionDesktop: string | null,
      },
      mobile: {
        metaTitleMobile: string | null,
        metaDescriptionMobile: string | null,
      }
    }
    languageId: number
  }
}

enum GetProductDimensionsResponseError {
}

export async function getProductDimensions(params: GetProductDimensionsRequestParams, output?: RequestOutput<GetProductDimensionsResponse, GetProductDimensionsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetProductDimensions,
    params: params,
    output: output,
  })
}



export type UpdateProductDimensionsRequestParams = {
  productId: number,
  width: number | null,
  height: number | null,
  depth: number | null,
  weight: number | null
}

type UpdateProductDimensionsResponse = {
}

enum UpdateProductDimensionsReponseError {
}

export async function updateProductDimensions(params: UpdateProductDimensionsRequestParams, output?: RequestOutput<UpdateProductDimensionsResponse, UpdateProductDimensionsReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateProductDimensions,
    params: params,
    output: output,
  })
}




export interface GetAllProductArtistsRequestParams {
  productId: number
  languageId: number
}
export interface GetAllProductArtistsResponse {
  artists: {
    id: number
    keyword: string
    published: boolean | null
    pageId: number
    parentId: number | null
    mainImage: {
      id: number
      type: string
      folder: number
      filename: string
      basepath: string
      path: string
    }
    year: number | null
    translation: {
      name: string
      shortDescription: string
    }[],
  }[]
}

enum GetAllProductArtistsResponseError {
}

export async function getAllProductArtists(params: GetAllProductArtistsRequestParams, output?: RequestOutput<GetAllProductArtistsResponse, GetAllProductArtistsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllProductArtists,
    params: params,
    output: output,
  })
}



//Retrieve the authentication token.
//Used when logging in and authorizing the client to make future API calls.

export type AddArtistToProductRequestParams = {
  productId: number
  artistId: number
}

export type AddArtistToProductResponse = {
}

enum AddArtistToProductReponseError {
}

export async function addArtistToProduct(params: AddArtistToProductRequestParams, output?: RequestOutput<AddArtistToProductResponse, AddArtistToProductReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.AddArtistToProduct,
    params: params,
    output: output,
  })
}


//Retrieve the authentication token.
//Used when logging in and authorizing the client to make future API calls.

export type RemoveArtistFromProductRequestParams = {
  productId: number
  artistId: number
}

export type RemoveArtistFromProductResponse = {
}

enum RemoveArtistFromProductReponseError {
}

export async function removeArtistFromProduct(params: RemoveArtistFromProductRequestParams, output?: RequestOutput<RemoveArtistFromProductResponse, RemoveArtistFromProductReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.RemoveArtistFromProduct,
    params: params,
    output: output,
  })
}




export type GetProductPickupAddressRequestParams = {
  productId: number,
}

export type GetProductPickupAddressResponse = {
  pickupAddress: {
    id: number
    description: string
    convelioAddressId: number
  }[]
}

enum GetProductPickupAddressReponseError {
}

export async function getProductPickupAddress(params: GetProductPickupAddressRequestParams, output?: RequestOutput<GetProductPickupAddressResponse, GetProductPickupAddressReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetProductPickupAddress,
    params: params,
    output: output,
  })
}



export type AddProductPickupAddressRequestParams = {
  productId: number,
  address: string
}

export type AddProductPickupAddressResponse = {
  pickupAddress: {
    id: number
    description: string
    convelioAddressId: number
  }
}

enum AddProductPickupAddressReponseError {
}

export async function addProductPickupAddress(params: AddProductPickupAddressRequestParams, output?: RequestOutput<AddProductPickupAddressResponse, AddProductPickupAddressReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.AddProductPickupAddress,
    params: params,
    output: output,
  })
}



export type RemoveProductPickupAddressRequestParams = {
  productId: number,
}

export type RemoveProductPickupAddressResponse = {
}

enum RemoveProductPickupAddressReponseError {
}

export async function removeProductPickupAddress(params: RemoveProductPickupAddressRequestParams, output?: RequestOutput<RemoveProductPickupAddressResponse, RemoveProductPickupAddressReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.RemoveProductPickupAddress,
    params: params,
    output: output,
  })
}


export interface GetProductGalleryRequestParams {
  productId: number
}

export interface GetProductGalleryResponse {
  productGallery: {
    id: number
    itemOrder: number
    image: {
      id: number
      filename: string,
      type: string,
      basepath: string
      folder: number
    }
  }[]
}

enum GetProductGalleryResponseError {
}

export async function getProductGallery(params: GetProductGalleryRequestParams, output?: RequestOutput<GetProductGalleryResponse, GetProductGalleryResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetProductGallery,
    params: params,
    output: output,
  })
}



export interface AddProductGalleryImageRequestParams {
  productId: number
  mediaFileId: number
}

export interface AddProductGalleryImageResponse {
}

enum AddProductGalleryImageResponseError {
}

export async function addProductGalleryImage(params: AddProductGalleryImageRequestParams, output?: RequestOutput<AddProductGalleryImageResponse, AddProductGalleryImageResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.AddProductGalleryImage,
    params: params,
    output: output,
  })
}



export interface RemoveProductGalleryImageRequestParams {
  id: number
}

export interface RemoveProductGalleryImageResponse {
}

enum RemoveProductGalleryImageResponseError {
}

export async function removeProductGalleryImage(params: RemoveProductGalleryImageRequestParams, output?: RequestOutput<RemoveProductGalleryImageResponse, RemoveProductGalleryImageResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.RemoveProductGalleryImage,
    params: params,
    output: output,
  })
}