import { makeStyles } from '@material-ui/core'
import { Color } from 'theme/style'
import {
  DatePicker, DatePickerProps,
  //TimePicker,
  //DateTimePicker,
  //MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { config } from 'app/config'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: 'flex',
  },
  input: {
    flex: 1,
    backgroundColor: Color.Background,
    padding: theme.spacing(0, 1.5),
    '& input': {
      cursor: 'pointer !important',
      padding: theme.spacing(0),
      textAlign: 'left',
      height: '100%',
    },
  },
}))

interface DateFieldProps extends DatePickerProps {
  className?: string
  name: string
  value: Date
  onUpdate?: (name: string, value: any) => void
  onConfirm?: (name: string, value: any) => void
}
const DateField = ({ ...props }: DateFieldProps) => {
  const classes = useStyles()



  //FIELD CHANGES

  function updateField(date: any) {
    const value = date

    if (props.onUpdate == null) return
    props.onUpdate(props.name, value)
  }


  //RENDER

  return (
    <div className={classes.inputContainer}>
      <DatePicker
        {...props}
        minDateMessage={null}
        className={clsx(classes.input, props.className)}
        disableToolbar variant="inline"
        format={config.app.defaultDateFormat}
        value={props.value} onChange={date => updateField(date)}
        labelFunc={props.labelFunc}
      />
    </div>
  )
}

export default DateField