import { Color, Value } from 'theme/style'
import spacing from 'theme/config/spacing'

const style = {
  root: {
    width: 48,
    height: 27,
    padding: 0,
    overflow: 'visible !important',
    margin: spacing(1),
  },
  switchBase: {
    top: '1px !important',
    padding: 1,
    color: Color.Error + ' !important',
    '&$checked': {
      transform: 'translateX(28px)',
      color: Color.Success + ' !important',
      '& + $track': {
        border: 'none',
        backgroundColor: Color.Background + '!important',
      },
    },
    '&focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 16,
    height: 24,
    borderRadius: Value.BorderRadius_Field,
    boxShadow: 'none',
    marginLeft: '1px !important'
  },
  track: {
    borderRadius: Value.BorderRadius_Field,
    backgroundColor: Color.Background + '!important',
    opacity: 1 + ' !important',
  },
  checked: {},
}

export default style
