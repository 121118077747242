import { Input, InputProps, makeStyles } from '@material-ui/core';
import { isValidEmail } from 'app/utils/validation';
import clsx from 'clsx';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  input: {
  },
}))


interface FieldInputProps extends InputProps {
  className?: string
  value: string | null
  updateValueCallback: (value: string | null, hasError: boolean) => void
  onBlur: (event: any) => void
}

const FieldInput = ({ className, value, updateValueCallback, onBlur }: FieldInputProps) => {
  const classes = useStyles()

  useEffect(() => {
    updateValueCallback(value, hasError())
  }, [])

  const hasError = (v?: string | null) => {
    if (v == null) v = value
    return isValidEmail(v) === false
  }

  function handleInputChange(event: any) {
    const target = event.target as HTMLInputElement
    let value = target.value

    updateValueCallback(value, hasError(value))
  }

  return (
    <Input disableUnderline className={clsx(className, classes.input)}
      value={value}
      type="text"
      placeholder="Inserisci un testo..."
      onChange={e => handleInputChange(e)}
      onBlur={e => onBlur(e)}
    />
  )
}

export default FieldInput
