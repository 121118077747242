import { makeStyles, Typography } from '@material-ui/core'
import { Currency } from 'app/entities/types'
import clsx from 'clsx'
import { getCurrencySymbol } from 'app/entities/methods';
import { Color, FontWeight } from 'theme/style';

const useStyles = makeStyles((theme) => ({
  symbol: {
    display: 'flex',
    backgroundColor: Color.Primary,
    color: Color.White,
    width: '20px',
    height: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    fontWeight: FontWeight.SemiBold
  },
  round: {
    borderRadius: '10px',
    overflow: 'hidden',
  },
}))


type LangugeFlagProps = {
  className?: string
  currency?: Currency
  round?: boolean
}
const LanguageFlag = ({ ...props }: LangugeFlagProps) => {
  const classes = useStyles()
  if (props.currency == null) return <></>

  const roundValue = props.round ?? true
  let roundClass = classes.round
  if (roundValue !== true) roundClass = ''

  return (
    <Typography className={clsx(classes.symbol, roundClass, props.className)}>{getCurrencySymbol(props.currency)}</Typography>
  )
}

export default LanguageFlag
