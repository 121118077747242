const props = {
  MuiButtonBase: {
    disableRipple: true
  },
  MuiGrid: {
    spacing: 2,
  },

}

export default props