import { User, Language, Currency, PageType, FieldGroupType, FieldType, MediaLibraryFolder } from 'app/entities/types'
import { SessionAction, SessionActionType } from 'app/session/actions'
import clone from 'lodash/cloneDeep'
import _ from 'lodash'

const initialState: SessionState = {
  app: {
    languages: [],
    currencies: [],
    pageTypes: [],
    fieldGroupTypes: [],
    fieldTypes: [],
    mediaLibraryFolders: [],
  },
  user: {
    isLogged: false,
    id: 0,
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    avatar: '',
    role: {
      id: 0,
      keyword: null,
      name: '',
      permissions: null
    },
  },
  selectedLanguage: {
    id: 0,
    name: '',
    code: '',
    locale: '',
    enabled: false
  },
  selectedCurrency: {
    id: 0,
    name: '',
    code: '',
    htmlSymbol: '',
  },
  persistedListParameters: [],
}

export type SessionPersistedListParameters = {
  listId: string
  page: number,
  rowsPerPage: number,
  sorting: {
    order?: string
    direction?: 'asc' | 'desc'
  }
  filters?: { [key: string]: any }
  query?: string
  fromDate?: Date | null
  toDate?: Date | null
}

export type SessionState = {
  app: {
    languages: Language[]
    currencies: Currency[]
    pageTypes: PageType[]
    fieldGroupTypes: FieldGroupType[]
    fieldTypes: FieldType[]
    mediaLibraryFolders: MediaLibraryFolder[]
  },
  user: User
  selectedLanguage: Language
  selectedCurrency: Currency
  persistedListParameters: SessionPersistedListParameters[]
}

export default function sessionReducer(state: SessionState = clone(initialState), action: SessionAction): SessionState {
  switch (action.type) {

    case SessionActionType.Login: {
      if (action.data === undefined) console.error('No user data received')

      Object.assign(state.user, action.data);
      state.user.isLogged = true
      return state
    }

    case SessionActionType.Logout: {
      Object.assign(state, clone(initialState))
      return { ...state }
    }

    case SessionActionType.UpdateUser: {
      if (action.data === undefined) console.error('No user data received')
      if (state.user.id !== action.data.id) {
        console.error('Tried to update the session user with data from another user: aborting')
        return state
      }

      Object.assign(state.user, action.data);
      return { ...state }
    }

    case SessionActionType.UpdateUserPermissions: {
      if (action.data === undefined) console.error('No user permissions data received')

      Object.assign(state.user.role, action.data);
      return { ...state }
    }

    case SessionActionType.UpdateLanguages: {
      if (action.data === undefined) console.error('No language data received')

      Object.assign(state.app.languages, action.data)
      return state
    }

    case SessionActionType.UpdateCurrencies: {
      if (action.data === undefined) console.error('No currency data received')

      Object.assign(state.app.currencies, action.data)
      return state
    }

    case SessionActionType.UpdateMediaLibraryFolders: {
      if (action.data === undefined) console.error('No media library folders data received')

      Object.assign(state.app.mediaLibraryFolders, action.data);
      return state
    }

    case SessionActionType.UpdatePageTypes: {
      if (action.data === undefined) {
        console.error('No page type data received, downloading...')
      }

      Object.assign(state.app.pageTypes, action.data);
      return state
    }

    case SessionActionType.UpdateFieldGroupTypes: {
      if (action.data === undefined) {
        console.error('No field group types data received, downloading...')
      }

      Object.assign(state.app.fieldGroupTypes, action.data);
      return state
    }

    case SessionActionType.UpdateFieldTypes: {
      if (action.data === undefined) {
        console.error('No field types data received, downloading...')
      }

      Object.assign(state.app.fieldTypes, action.data);
      return state
    }

    case SessionActionType.UpdatePersistedListParameters: {
      if (action.data === undefined) {
        console.error('No persisted list parameters data received')
        return state
      }

      let newObject = clone(state.persistedListParameters)
      let index = _.findIndex(newObject, { listId: action.data.listId })
      if (index === -1) newObject.push(action.data)
      else newObject.splice(index, 1, action.data)

      Object.assign(state.persistedListParameters, newObject);
      return state
    }

    case SessionActionType.ResetPersistedListParameters: {
      state.persistedListParameters = []
      return state
    }

    case SessionActionType.UpdateSelectedLanguage: {
      if (action.data === undefined) {
        console.error('No language data received')
        return state
      }

      Object.assign(state.selectedLanguage, action.data);
      return state
    }

    case SessionActionType.UpdateSelectedCurrency: {
      if (action.data === undefined) {
        console.error('No currency data received')
        return state
      }

      Object.assign(state.selectedCurrency, action.data);
      return state
    }

    default: {
      return state
    }
  }
}
