import { FontWeight, Color } from 'theme/style'

const style = {
  day: {
  },
  daySelected: {
    backgroundColor: Color.Secondary + '!important',
    '& p': {
      color: Color.White,
      fontWeight: FontWeight.Medium,
      backgroundColor: Color.Secondary
    }
  },
  current: {
    border: '2px solid',
    borderColor: Color.Secondary,
    '& p': {
      color: Color.Secondary,
      fontWeight: FontWeight.Medium,
    }
  }
}

export default style
