import React from 'react'
import { makeStyles, Collapse } from '@material-ui/core'
import clsx from 'clsx'
import { SnackbarProvider } from 'notistack'
import { SnackbarUtilsConfigurator } from 'app/utils'
import { Color, FontSize, FontWeight, Value } from 'theme/style'

const useStyles = makeStyles((theme) => ({
  default: {
    width: '200px',
    borderRadius: Value.BorderRadius,
    fontSize: FontSize.Body1,
    fontWeight: FontWeight.SemiBold,
    boxShadow: '12px 12px 24px 0 rgba(0,0,0,0.08) !important',
    marginBottom: '20px !important',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '15px !important',
      width: `calc(100vw - 20px)`,
    },
  },
  production: {
  },
  success: {
    backgroundColor: Color.Success + ' !important',
    color: Color.White + ' !important'
  },
  error: {
    backgroundColor: Color.Error + ' !important',
    color: Color.White + ' !important'
  },
  warning: {
    backgroundColor: Color.Warning + ' !important',
    color: Color.White + ' !important'
  },
  info: {
    backgroundColor: Color.Primary + ' !important',
    color: Color.White + ' !important'
  },
}))

interface ToastProviderProps {
}

const ToastProvider = ({ ...props }: ToastProviderProps) => {
  const classes = useStyles()

  const productionClass: any = process.env.REACT_APP_ENVNAME === 'production' && classes.production

  return (
    <SnackbarProvider
      maxSnack={1}
      hideIconVariant
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transitionDuration={{ enter: 150, exit: 150 }}
      TransitionComponent={Collapse}
      classes={{
        variantSuccess: clsx(classes.default, classes.success, productionClass),
        variantError: clsx(classes.default, classes.error, productionClass),
        variantWarning: clsx(classes.default, classes.warning, productionClass),
        variantInfo: clsx(classes.default, classes.info, productionClass),
      }}
    >
      <SnackbarUtilsConfigurator />
    </SnackbarProvider>
  )
}

export default ToastProvider