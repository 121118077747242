import React, { useState, useEffect } from 'react'
import clone from 'lodash/cloneDeep';
import { makeStyles, Card, Typography } from '@material-ui/core'
import { CardField, CardHeader, CardContent, FieldsListErrors, PageEntityDetailsRefreshableProps } from 'components'
import { processFieldsErrors, prepareForSave, shouldSave, useLocalization } from 'components/methods'
import { Client, Claim } from 'app/entities/types'
import clsx from 'clsx'
import { toast } from 'app/utils'
import {
	updateClient, UpdateClientRequestParams,
	updateClaim, UpdateClaimRequestParams,
} from 'app/api'
import { Color, FontWeight } from 'theme/style';

const useStyles = makeStyles((theme) => ({
	warning: {
		color: Color.Error,
		fontWeight: FontWeight.Medium,
	},
	lastWarning: {
		marginBottom: theme.spacing(1)
	}
}))

enum FieldName {
	ClientNumber = 'clientNumber',
	PolicyNumber = 'policyNumber',
	FirstName = 'firstname',
	LastName = 'lastname',
	BusinessName = 'businessName',
	FiscalCode = 'fiscalCode',
	Email = 'email',
	Phone = 'phone',
	Address = 'address',
	City = 'city',
	Zip = 'zip',
}

type DataProps = {
	[FieldName.ClientNumber]: string | null
	[FieldName.PolicyNumber]: string | null
	[FieldName.FirstName]: string | null
	[FieldName.LastName]: string | null
	[FieldName.BusinessName]: string | null
	[FieldName.FiscalCode]: string | null
	[FieldName.Email]: string | null
	[FieldName.Phone]: string | null
	[FieldName.Address]: string | null
	[FieldName.City]: string | null
	[FieldName.Zip]: string | null
}

interface CardClientProps extends PageEntityDetailsRefreshableProps {
	client?: Client,
	claim?: Claim,
	title?: string
}

const CardClient = ({ ...props }: CardClientProps) => {
	const classes = useStyles()
	const { t } = useLocalization()
	const [isLoading, setIsLoading] = useState(false)

	let isClient = props.client != null
	let isClaim = props.claim != null

	let hasClient = isClient === true
	if (hasClient === false) hasClient = props.claim?.policy?.client != null

	let hasPolicy = props.claim?.policy != null
	let hasClaim = props.claim != null



	//OBJECT

	const [data, setData] = useState<DataProps>({
		[FieldName.ClientNumber]: props.client?.clientNumber ?? props.claim?.policy?.client?.clientNumber ?? null,
		[FieldName.PolicyNumber]: props.claim?.policy?.policyNumber ?? null,
		[FieldName.FirstName]: props.claim?.firstname ?? props.claim?.policy?.client?.firstname ?? props.client?.firstname ?? null,
		[FieldName.LastName]: props.claim?.lastname ?? props.claim?.policy?.client?.lastname ?? props.client?.lastname ?? null,
		[FieldName.BusinessName]: props.claim?.businessName ?? props.claim?.policy?.client?.businessName ?? props.client?.businessName ?? null,
		[FieldName.FiscalCode]: props.claim?.fiscalCode ?? props.claim?.policy?.client?.fiscalCode ?? props.client?.fiscalCode ?? null,
		[FieldName.Email]: props.claim?.email ?? props.claim?.policy?.client?.email ?? props.client?.email ?? null,
		[FieldName.Phone]: props.claim?.phone ?? props.claim?.policy?.client?.phone ?? props.client?.phone ?? null,
		[FieldName.Address]: props.claim?.address ?? props.claim?.policy?.client?.address ?? props.client?.address ?? null,
		[FieldName.City]: props.claim?.city ?? props.claim?.policy?.client?.city ?? props.client?.city ?? null,
		[FieldName.Zip]: props.claim?.zip ?? props.claim?.policy?.client?.zip ?? props.client?.zip ?? null,
	})
	const [currentData, setCurrentData] = useState(data)
	const [isUpdatingCurrentData, setIsUpdatingCurrentData] = useState(false)

	// function updateData(data: DataProps) {
	// 	setIsUpdatingCurrentData(true)
	// 	setData(data)
	// 	setCurrentData(data)
	// }

	useEffect(() => {
		setIsUpdatingCurrentData(false)
	}, [currentData])

	useEffect(() => {
		const initialized = props.claim != null || props.client != null
		setIsLoading(!initialized)
	}, [props.claim, props.client])



	//ERROR HANDLING

	const errorsConditions: FieldsListErrors = {
	}

	const [errors, setErrors] = useState<FieldsListErrors>({})

	useEffect(() => {
		setErrors(processFieldsErrors(FieldName, errorsConditions))
	}, [data])



	//FIELD UPDATES

	function updateFieldValue(name: string, value: any) {
		const temp = clone(data)

		switch (name) {
			case FieldName.ClientNumber: temp.clientNumber = value; break
			case FieldName.PolicyNumber: temp.policyNumber = value; break
			case FieldName.FirstName: temp.firstname = value; break
			case FieldName.LastName: temp.lastname = value; break
			case FieldName.BusinessName: temp.businessName = value; break
			case FieldName.FiscalCode: temp.fiscalCode = value; break
			case FieldName.Email: temp.email = value; break
			case FieldName.Phone: temp.phone = value; break
			case FieldName.Address: temp.address = value; break
			case FieldName.City: temp.city = value; break
			case FieldName.Zip: temp.zip = value; break
			default: break
		}

		setData(temp)
	}

	function confirmFieldValue(name: string, value: any) {
		save(name as FieldName)
	}



	//SAVE

	function save(name?: FieldName) {
		if (shouldSave({ isUpdatingData: isUpdatingCurrentData, fieldName: name, fieldParameters: errorsConditions, data: data, currentData: currentData }) === false) return
		prepareForSave({ fieldName: name, data: data, currentData: currentData, updateDataCallback: setCurrentData })

		if (props.client != null) saveClientObject()
		else if (props.claim != null) saveClaimObject()

		function saveClientObject() {
			const encode = (): UpdateClientRequestParams => {
				return {
					id: props.client!.id,
					clientNumber: data.clientNumber,
					firstname: data.firstname,
					lastname: data.lastname,
					businessName: data.businessName,
					fiscalCode: data.fiscalCode,
					email: data.email,
					phone: data.phone,
					address: data.address,
					city: data.city,
					zip: data.zip,
				}
			}

			updateClient(encode(), {
				response(data) {
					toast.success(t('alert.client.update'))
					if (props.refreshObject != null) props.refreshObject()
				},
				error(error, message) {
					toast.error(message)
				}
			})
		}

		function saveClaimObject() {
			const encode = (): UpdateClaimRequestParams => {
				return {
					id: props.claim!.id,
					firstname: data.firstname,
					lastname: data.lastname,
					businessName: data.businessName,
					fiscalCode: data.fiscalCode,
					email: data.email,
					phone: data.phone,
					address: data.address,
					city: data.city,
					zip: data.zip,
					// policy: props.claim?.policy?.id
				}
			}

			updateClaim(encode(), {
				response(data) {
					toast.success(t('alert.claim.update'))
					if (props.refreshObject != null) props.refreshObject()
				},
				error(error, message) {
					toast.error(message)
				}
			})
		}
	}



	//SAVE

	const disableEditing = !isClient

	return (
		<Card>
			<CardHeader title={props.title ?? t('component.CardClient.title')} isLoading={isLoading} />
			<CardContent isLoading={isLoading}>
				{hasClient === false
					?
					<Typography className={classes.warning} variant={'body1'}>Questo sinistro non ha un cliente associato</Typography>
					:
					<CardField type={'text'} name={FieldName.ClientNumber}
						disabled={disableEditing}
						label={t('component.CardClient.client-code.label')} placeholder={t('component.CardClient.client-code.placeholder')}
						value={data.clientNumber} errors={errors}
						onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
					/>
				}

				{(isClaim === true && hasPolicy === false)
					?
					<Typography className={clsx(classes.warning, classes.lastWarning)} variant={'body1'}>Questo sinistro non ha una polizza associata</Typography>
					:
					<>
						{isClient === false &&
							<CardField type={'text'} name={FieldName.PolicyNumber}
								disabled={disableEditing}
								label={t('component.CardClient.policy-number.label')} placeholder={t('component.CardClient.policy-number.placeholder')}
								value={data.policyNumber} errors={errors}
								onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
							/>
						}
					</>
				}

				{(hasClaim === true || isClient === true) &&
					<>
						< CardField type={'text'} name={FieldName.FirstName}
							disabled={disableEditing}
							label={t('component.CardClient.firstname.label')} placeholder={t('component.CardClient.firstname.placeholder')}
							value={data.firstname} errors={errors}
							onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
						/>
						<CardField type={'text'} name={FieldName.LastName}
							disabled={disableEditing}
							label={t('component.CardClient.lastname.label')} placeholder={t('component.CardClient.lastname.placeholder')}
							value={data.lastname} errors={errors}
							onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
						/>
						<CardField type={'text'} name={FieldName.BusinessName}
							disabled={disableEditing}
							label={t('component.CardClient.business-name.label')} placeholder={t('component.CardClient.business-name.placeholder')}
							value={data.businessName} errors={errors}
							onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
						/>
						<CardField type={'text'} name={FieldName.FiscalCode}
							disabled={disableEditing}
							label={t('component.CardClient.fiscal-code.label')} placeholder={t('component.CardClient.fiscal-code.placeholder')}
							value={data.fiscalCode} errors={errors}
							onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
						/>
						<CardField type={'text'} name={FieldName.Email}
							disabled={disableEditing}
							label={t('component.CardClient.email.label')} placeholder={t('component.CardClient.email.placeholder')}
							value={data.email} errors={errors}
							onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
						/>
						<CardField type={'text'} name={FieldName.Phone}
							disabled={disableEditing}
							label={t('component.CardClient.phone.label')} placeholder={t('component.CardClient.phone.placeholder')}
							value={data.phone} errors={errors}
							onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
						/>
						<CardField type={'text'} name={FieldName.Address}
							disabled={disableEditing}
							label={t('component.CardClient.address.label')} placeholder={t('component.CardClient.address.placeholder')}
							value={data.address} errors={errors}
							onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
						/>
						<CardField type={'text'} name={FieldName.City}
							disabled={disableEditing}
							label={t('component.CardClient.city.label')} placeholder={t('component.CardClient.city.placeholder')}
							value={data.city} errors={errors}
							onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
						/>
						<CardField type={'text'} name={FieldName.Zip}
							disabled={disableEditing}
							label={t('component.CardClient.zip.label')} placeholder={t('component.CardClient.zip.placeholder')}
							value={data.zip} errors={errors}
							onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
						/>
					</>
				}
			</CardContent>
		</Card>
	)
}

export default CardClient