import { UserRoleValue, UserPermissionValue } from 'app/values'
import { User } from 'app/entities/types'
import { userRoleIs, userRoleHasPermission } from 'app/entities/methods'
import { routesAuth } from 'app/routes'

export function userIs(u: User, r: UserRoleValue): boolean {
  if (u.role == null) return false
  return userRoleIs(u.role, r) === true
}

export function userCan(u: User, p: UserPermissionValue): boolean {
  if (u.role == null) return false
  return userRoleHasPermission(u.role, p)
}

export function userHasAccessToRoute(user: User, route: string) {
  if (route == null) return true

  let allowed = true
  routesAuth.forEach(a => {
    a.availableRoutes.forEach(r => {
      if (allowed === true && route.includes(r) === true) {
        allowed = userCan(user, a.permission) === true
      }
    })
  })

  return allowed
}

export function getUserName(u: User): string {
  return u.firstname + ' ' + u.lastname
}
