import { Card } from '@material-ui/core'
import { Page } from 'app/entities/types'
import { CardContent, CardHeader, CardField, FieldsListErrors, PageEntityDetailsRefreshableProps } from 'components'
import { processFieldsErrors, prepareForSave, shouldSave, useLocalization } from 'components/methods'
import {
  updatePageStatus, UpdatePageStatusRequestParams,
} from 'app/api';
import { useState, useEffect } from 'react'
import { toast } from 'app/utils';
import clone from 'lodash/cloneDeep';


enum FieldName {
  PagePublished = 'pagePublished',
}

type DataProps = {
  id: number
  [FieldName.PagePublished]: boolean
}

interface CardPageStatusProps extends PageEntityDetailsRefreshableProps {
  object: Page,
  pageId: number
  children?: any
}

const CardPageStatus = ({ ...props }: CardPageStatusProps) => {
  const { t } = useLocalization()

  const [isLoading, setIsLoading] = useState(true)



  //DATA

  const [data, setData] = useState<DataProps>({
    id: props.object.id,
    [FieldName.PagePublished]: props.object.published ?? true,
  })
  const [currentData, setCurrentData] = useState(data)
  const [isUpdatingCurrentData, setIsUpdatingCurrentData] = useState(false)

  // function updateData(data: DataProps) {
  //   setIsUpdatingCurrentData(true)
  //   setData(data)
  //   setCurrentData(data)
  // }

  useEffect(() => {
    setIsUpdatingCurrentData(false)
  }, [currentData])

  useEffect(() => {
    const initialized = props.object != null
    setIsLoading(!initialized)
  }, [props.object])



  //ERROR HANDLING

  const errorsConditions: FieldsListErrors = {
  }

  const [errors, setErrors] = useState<FieldsListErrors>({})

  useEffect(() => {
    setErrors(processFieldsErrors(FieldName, errorsConditions))
  }, [data])



  //FIELD UPDATES

  function updateFieldValue(name: string, value: any) {
    const temp = clone(data)

    switch (name) {
      case FieldName.PagePublished: temp.pagePublished = Boolean(value); break
      default: break
    }

    setData(temp)
  }

  function confirmFieldValue(name: string, value: any) {
    save(name as FieldName)
  }



  //SAVE

  function save(name?: FieldName) {
    if (shouldSave({ isUpdatingData: isUpdatingCurrentData, fieldName: name, fieldParameters: errorsConditions, data: data, currentData: currentData }) === false) return
    prepareForSave({ fieldName: name, data: data, currentData: currentData, updateDataCallback: setCurrentData })

    performSave()

    function performSave() {
      const encode = (): UpdatePageStatusRequestParams => {
        return {
          id: props.pageId,
          published: data.pagePublished ?? false,
        }
      }

      updatePageStatus(encode(), {
        response(data) {
          toast.success(t('alert.page-data.status.update'))
          if (props.refreshObject != null) props.refreshObject()
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }
  }



  //RENDER

  return (
    <>
      <Card>
        <CardHeader title={t('tab.page-data.status.title')} />
        <CardContent isLoading={isLoading}>

          <CardField type={'boolean'} layout={'horizontal'} name={FieldName.PagePublished}
            label={t('tab.page-data.status.public.label')} description={t('tab.page-data.status.public.description')}
            value={data.pagePublished} errors={errors}
            onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
          />

          {props.children}

        </CardContent>
      </Card>
    </>
  )
}

export default CardPageStatus