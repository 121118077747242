import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'
import { API_Client } from 'app/api/project/clients'

enum APIEndpoint {
  GetAllPolicies = 'api/policies',
  GetPolicyById = 'api/policies/getById',
  CreatePolicy = 'api/policies/create',
  UpdatePolicy = 'api/policies/update',
  DeletePolicy = 'api/policies/delete',
}

export interface API_Policy {
  id: number
  policyNumber?: string | null
  client?: API_Client | null
}



export interface GetAllPoliciesRequestParams extends APIPaginatable, APISortable, APISearchable {
}
export interface GetAllPoliciesResponse extends APIPaginatableResponse {
  policies: API_Policy[]
}
enum GetAllPoliciesResponseError {
}
export async function getAllPolicies(params: GetAllPoliciesRequestParams, output?: RequestOutput<GetAllPoliciesResponse, GetAllPoliciesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllPolicies,
    params: params,
    output: output,
  })
}



export type GetPolicyRequestParams = {
  id: number
}
export type GetPolicyResponse = {
  policy: API_Policy
}
enum GetPolicyResponseError {
}
export async function getPolicy(params: GetPolicyRequestParams, output?: RequestOutput<GetPolicyResponse, GetPolicyResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetPolicyById,
    params: params,
    output: output,
  })
}



export type CreatePolicyRequestParams = {
}
type CreatePolicyResponse = {
  policy: API_Policy
}
enum CreatePolicyReponseError {
}
export async function createPolicy(params: CreatePolicyRequestParams, output?: RequestOutput<CreatePolicyResponse, CreatePolicyReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreatePolicy,
    params: params,
    output: output,
  })
}



export interface UpdatePolicyRequestParams extends API_Policy {
  clientId?: number | null
}
type UpdatePolicyResponse = {
}
enum UpdatePolicyReponseError {
}
export async function updatePolicy(params: UpdatePolicyRequestParams, output?: RequestOutput<UpdatePolicyResponse, UpdatePolicyReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdatePolicy,
    params: params,
    output: output,
  })
}



export type DeletePolicyRequestParams = {
  id: number
}
type DeletePolicyResponse = {
}
enum DeletePolicyReponseError {
}
export async function deletePolicy(params: DeletePolicyRequestParams, output?: RequestOutput<DeletePolicyResponse, DeletePolicyReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeletePolicy,
    params: params,
    output: output,
  })
}