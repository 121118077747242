import { makeStyles, Chip, ChipProps } from '@material-ui/core'
import { Tag } from 'app/entities/types'
import { tagClasses, tagClassFromTag } from 'components/methods'

const useStyles = makeStyles((theme) => ({
  ...tagClasses()
}))

interface TagChipType extends ChipProps {
  tag: Tag,
}

const TagChip = (props: TagChipType) => {
  const classes: any = useStyles()

  let tagClass = tagClassFromTag(props.tag, classes)

  return (
    <Chip className={tagClass} key={props.tag.id} size="small" label={props.tag.name} />
  )
}

export default TagChip
