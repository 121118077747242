import { Value } from 'theme/style'

const style = {
  root: {
    borderRadius: Value.BorderRadius,
    overflow: 'hidden',
    padding: '0 !important',
  },
}

export default style
