import { Color } from "theme/style"

const style = {
  root: {
    borderRadius: '1.6rem'
  },
  button: {
    '&:hover': {
      backgroundColor: Color.Background
    }
  }
}

export default style
