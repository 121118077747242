import React, { ReactNode } from 'react'
import { DialogProps, Dialog, DialogTitle, DialogContent, DialogActions, Typography, makeStyles } from '@material-ui/core'
import { Button, ButtonCategory } from 'components'
import { useLocalization } from 'components/methods'
import { Color } from 'theme/style'

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '700px',
    maxWidth: '95%'
  },
  description: {
    marginTop: theme.spacing(1),
    color: Color.TextSecondary
  }
}))


export interface CustomDialogProps extends DialogProps {
  description?: string | React.ReactNode
  confirmText?: string
  content?: ReactNode
  confirmButtonCategory?: ButtonCategory
  actions?: React.ReactNode
  onConfirm?: () => void
  onClose?: () => void
}


const CustomDialog = ({ ...props }: CustomDialogProps) => {
  const classes = useStyles()
  const { t } = useLocalization()

  function handleClose() {
    if (props.onClose != null) props.onClose()
  }

  const confirmDialog = () => {
    handleClose()
    if (props.onConfirm != null) props.onConfirm()
  }

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} classes={{ paper: classes.dialog }}>
        <DialogTitle>
          <Typography component="h3" variant="h3">{props.title}</Typography>
          <Typography className={classes.description} component="p" variant="body1">{props.description}</Typography>
        </DialogTitle>
        <DialogContent>
          {props.content}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text">{t('common.cancel')}</Button>
          {props.actions}
          {props.onConfirm != null && <Button category={props.confirmButtonCategory ?? 'confirm'} prominent={true} onClick={confirmDialog}>{props.confirmText ?? t('common.confirm')}</Button>}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CustomDialog
