import { CardContent as Content, CardContentProps as ContentProps, makeStyles } from '@material-ui/core';
import { Spinner } from 'components';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    minHeight: '100px',
    paddingTop: 0,
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: 0
    },
  },
  container: {
    height: '100%',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    minHeight: '100px',
    height: '100%',
  }
}))


interface CardContentProps extends ContentProps {
  isLoading?: boolean,
}
const CardContent = ({ ...props }: CardContentProps) => {
  const classes = useStyles()

  return (
    <Content className={clsx(classes.root)} >
      {props.isLoading === true && <div className={classes.loading}><Spinner /></div>}
      <div style={props.isLoading ? { opacity: 0.0, visibility: 'hidden' } : { opacity: 1, visibility: 'visible' }} className={clsx(props.className, classes.container)}>
        {props.children}
      </div>
    </Content>
  )
}

export default CardContent
