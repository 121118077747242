import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import 'assets/stylesheets/quill.css';
import clsx from 'clsx';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  richTextEditor: {
    position: 'relative',
    height: '100%',
  },

  richTextSizeDefault: {
    minHeight: '200px',
    maxHeight: '200px',
  },
  richTextSizeLarge: {
    minHeight: '200px',
    maxHeight: '70vh',
  }
}))

export enum RichTextFieldToolbarType {
  Standard,
  RichText,
  Editor,
  Media
}
export enum RichTextFieldMaxSize {
  Default,
  Large,
}

interface RichTextFieldProps {
  className?: string
  value: string
  placeholder?: string
  name: string
  toolbarType?: RichTextFieldToolbarType
  maxSize?: RichTextFieldMaxSize
  onChangeCallback?: (value: string, name?: string) => void
  onBlurCallback?: (name?: string) => void
  onUpdate?: (name: string, value: any) => void
  onConfirm?: (name: string, value: any) => void
}
const RichTextField = ({ ...props }: RichTextFieldProps) => {
  const classes = useStyles()
  const [editorValue, setEditorValue] = useState(props.value)
  const [editorDelta, setEditorDelta] = useState<any | null>(null)


  //Override link.
  var Inline = Quill.import('blots/inline');
  class LinkBlot extends Inline {
    static create(value: any) {
      let node = super.create();
      node.setAttribute('href', value);
      return node;
    }

    static formats(node: any) {

      return node.getAttribute('href');
    }
  }
  LinkBlot.blotName = 'link';
  LinkBlot.tagName = 'a';
  Quill.register(LinkBlot);


  //Toolbar.
  let editorToolbar = []

  if (props.toolbarType == null || props.toolbarType === RichTextFieldToolbarType.Standard) {
    editorToolbar.push(['bold', 'italic', 'underline'])
    editorToolbar.push(['link'])
    editorToolbar.push(['clean'])
  }
  if (props.toolbarType === RichTextFieldToolbarType.RichText) {
    editorToolbar.push(['bold', 'italic', 'underline', 'blockquote'])
    editorToolbar.push([{ 'list': 'ordered' }, { 'list': 'bullet' }])
    editorToolbar.push(['link'])
    editorToolbar.push(['clean'])
  }
  if (props.toolbarType === RichTextFieldToolbarType.Editor) {
    editorToolbar.push([{ 'header': [2, 3, 4, 5, 6, false] }])
    editorToolbar.push(['bold', 'italic', 'underline', 'blockquote'])
    editorToolbar.push([{ 'list': 'ordered' }, { 'list': 'bullet' }])
    editorToolbar.push(['link'])
    editorToolbar.push(['clean'])
  }
  if (props.toolbarType === RichTextFieldToolbarType.Media) {
    editorToolbar.push([{ 'header': [2, 3, 4, 5, 6, false] }])
    editorToolbar.push(['bold', 'italic', 'underline', 'blockquote'])
    editorToolbar.push([{ 'list': 'ordered' }, { 'list': 'bullet' }])
    editorToolbar.push(['link', 'image', 'video'])
    editorToolbar.push(['clean'])
  }

  const editorModules = {
    toolbar: editorToolbar
  }

  let richTextClass = classes.richTextSizeDefault
  if (props.maxSize === RichTextFieldMaxSize.Default) richTextClass = classes.richTextSizeDefault
  if (props.maxSize === RichTextFieldMaxSize.Large) richTextClass = classes.richTextSizeLarge



  //Value updates.

  useEffect(() => {
    setEditorValue(props.value)
  }, [props.value])

  function handleEditorChange(value: string, delta: any, source: any, editor: any, name?: string) {
    let diff = null
    if (editorDelta != null) diff = _.difference(delta, editorDelta)

    setEditorValue(value)
    setEditorDelta(delta)

    if (diff != null ?? (diff as any).ops.length) {
      if (props.onChangeCallback != null) props.onChangeCallback(value, name)
      if (props.onUpdate != null) props.onUpdate(props.name, value)
    }
  }

  function handleEditorBlur(name?: string) {
    if (props.onBlurCallback != null) props.onBlurCallback(name)
  }


  //Render.

  return (
    <div className={classes.richTextEditor} onBlur={v => handleEditorBlur(props.name)}>
      <ReactQuill
        className={clsx(props.className, richTextClass)}
        placeholder={props.placeholder ?? ''}
        value={editorValue}
        modules={editorModules}
        onChange={(v, d, s, e) => handleEditorChange(v, d, s, e, props.name)}
      />
    </div>
  )
}

export default RichTextField