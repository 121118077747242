
const style = {
  root: {
  },
  padding: {
    padding: '0 !important'
  }
}

export default style
