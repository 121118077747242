import { Switch, InputProps } from '@material-ui/core';
import { useEffect } from 'react';


interface FieldInputProps extends InputProps {
  className?: string
  value: string | null
  updateValueCallback: (value: string | null, hasError: boolean) => void
  onBlur: (event: any) => void
}

const FieldInput = ({ className, value, updateValueCallback, onBlur }: FieldInputProps) => {
  useEffect(() => {
    updateValueCallback(value, hasError())
  }, [])

  const hasError = (v?: string) => {
    return false
  }

  function handleInputChange(event: any) {
    const target = event.target as HTMLInputElement
    let value = target.checked
    const formattedValue = value === false ? '0' : '1'
    updateValueCallback(formattedValue, hasError(formattedValue))
  }

  return (
    <Switch
      checked={(value === '0' || value === '') ? false : true}
      onChange={e => handleInputChange(e)}
      onBlur={e => onBlur(e)}
    />
  )
}

export default FieldInput
