
//Defines all the Tags available in the app.
//IMPORTANT: Must be mapped to each specific page tag keyword to work properly.
export enum TagValue {

  //Default tags.
  System = 'system',
  ThankYou = 'thank-you',
  Policy = 'policy',
  Error = 'error',
  Shop = 'shop',
  Onboarding = 'onboarding',

  ProductPage = 'product-page',
  ServicePage = 'service-page',
}