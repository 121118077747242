import { MediaLibraryFile } from 'app/entities/types'
import _ from 'lodash'
import { getBaseName, getFileName } from 'app/utils';

export const mediaLibraryFileIsImage = (file?: MediaLibraryFile | null): boolean => {
  if (file == null) return false
  if (file.format == null) return false

  const formats = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'tif', 'tiff', 'svg']
  if (formats.includes(file.format.toLowerCase()) === true) return true
  return false
}

export const mediaLibraryFileShouldUseIconPreview = (file?: MediaLibraryFile | null): boolean => {
  if (file == null) return false
  if (file.format == null) return false

  const formats = ['svg']
  if (formats.includes(file.format.toLowerCase()) === true) return true
  return false
}

export const getMediaLibraryFileName = (file?: MediaLibraryFile | null): string => {
  if (file == null) return ''
  const name = file.name.substring(0, file.name.lastIndexOf("-"))
  return name
}

export const getMediaLibraryFileFormat = (file?: MediaLibraryFile | null): string => {
  if (file == null) return ''
  const format = file.name.substr(file.name.lastIndexOf('.') + 1)
  return format
}

export const getMediaLibraryFileFullName = (file?: MediaLibraryFile | null): string => {
  if (file == null) return ''
  return file.name + '.' + file.format
}

export const rebuildMediaLibraryFilesData = (list?: MediaLibraryFile[]): MediaLibraryFile[] => {
  if (list == null) return []

  list.forEach(i => {
    validateItem(i)
  })

  return _.clone(list)

  function validateItem(item: MediaLibraryFile) {
    const basename = getBaseName(item.path)
    let filename = getFileName(basename)
    item.name = filename
  }
}

export type MediaLibraryFileMetatagStatus = 'good' | 'missing' | 'unavailable' | 'optional'

export function getMediaLibraryFileMetadataStatus(file?: MediaLibraryFile | null): MediaLibraryFileMetatagStatus {
  if (file == null) return 'unavailable'
  if (mediaLibraryFileIsImage(file) === false) return 'optional'
  if (file.translation == null) return 'unavailable'

  const hasTitle = file.translation.title != null && file.translation.title.length > 0
  const hasAlt = file.translation.alt != null && file.translation.alt.length > 0

  if (hasTitle === true && hasAlt === true) return 'good'
  return 'missing'
}