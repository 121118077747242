import { Card, Grid } from '@material-ui/core'
import { OrderShippingAddress } from 'app/entities/types'
import { PageEntityDetailsRefreshableProps, CardContent, CardHeader, CardField } from 'components'
import { useLocalization } from 'components/methods'
import React, { useState, useEffect } from 'react'

enum FieldName {
	Firstname = 'firstname',
	Lastname = 'lastname',
	Email = 'email',
	Phone = 'phone',
	Address1 = 'address1',
	Address2 = 'address2',
	City = 'city',
	Zip = 'zip',
	State = 'state',
	Country = 'country',
}

type DataProps = {
	id: number
	[FieldName.Firstname]: string,
	[FieldName.Lastname]: string,
	[FieldName.Email]: string,
	[FieldName.Phone]: string,
	[FieldName.Address1]: string
	[FieldName.Address2]: string
	[FieldName.City]: string
	[FieldName.Zip]: string
	[FieldName.State]: string
	[FieldName.Country]: string
}

interface ShippingProps extends PageEntityDetailsRefreshableProps {
	object?: OrderShippingAddress,
	title?: string
}

const Shipping = ({ ...props }: ShippingProps) => {
	const { t } = useLocalization()
	const [isLoading, setIsLoading] = useState(true)


	//OBJECT

	const [data] = useState<DataProps>({
		id: props.object?.id ?? 0,
		[FieldName.Firstname]: props.object?.firstname ?? '',
		[FieldName.Lastname]: props.object?.lastname ?? '',
		[FieldName.Email]: props.object?.email ?? '',
		[FieldName.Phone]: props.object?.phone ?? '',
		[FieldName.Address1]: props.object?.address1 ?? '',
		[FieldName.Address2]: props.object?.address2 ?? '',
		[FieldName.City]: props.object?.city ?? '',
		[FieldName.Zip]: props.object?.zip ?? '',
		[FieldName.State]: props.object?.state ?? '',
		[FieldName.Country]: props.object?.country ?? '',
	})

	// const [currentData, setCurrentData] = useState(data)
	// const [isUpdatingCurrentData, setIsUpdatingCurrentData] = useState(false)

	// useEffect(() => {
	// 	setIsUpdatingCurrentData(false)
	// }, [currentData])

	useEffect(() => {
		const initialized = props.object != null
		setIsLoading(!initialized)
	}, [props.object])


	//RENDER

	return (
		<>
			<Card>
				<CardHeader title={props.title ?? t('component.CardShopOrderAddress.title')} />
				<CardContent isLoading={isLoading}>
					<CardField disabled type={'text'} name={FieldName.Firstname}
						label={t('component.CardShopOrderAddress.firstname.label')} placeholder={t('component.CardShopOrderAddress.firstname.placeholder')}
						value={data.firstname}
					/>

					<CardField disabled type={'text'} name={FieldName.Lastname}
						label={t('component.CardShopOrderAddress.lastname.label')} placeholder={t('component.CardShopOrderAddress.lastname.placeholder')}
						value={data.lastname}
					/>

					<CardField disabled type={'text'} name={FieldName.Email}
						label={t('component.CardShopOrderAddress.email.label')} placeholder={t('component.CardShopOrderAddress.email.placeholder')}
						value={data.email}
					/>

					<CardField disabled type={'text'} name={FieldName.Phone}
						label={t('component.CardShopOrderAddress.phone.label')} placeholder={t('component.CardShopOrderAddress.phone.placeholder')}
						value={data.phone}
					/>

					<CardField disabled type={'text'} name={FieldName.Address1}
						label={t('component.CardShopOrderAddress.address1.label')} placeholder={t('component.CardShopOrderAddress.address1.placeholder')}
						value={data.address1}
					/>

					<CardField disabled type={'text'} name={FieldName.Address2}
						label={t('component.CardShopOrderAddress.address2.label')} placeholder={t('component.CardShopOrderAddress.address2.placeholder')}
						value={data.address2}
					/>

					<CardField disabled type={'text'} name={FieldName.City}
						label={t('component.CardShopOrderAddress.city.label')} placeholder={t('component.CardShopOrderAddress.city.placeholder')}
						value={data.city}
					/>

					<Grid container>
						<Grid item md={4}>
							<CardField disabled type={'text'} name={FieldName.Zip}
								label={t('component.CardShopOrderAddress.zip.label')} placeholder={t('component.CardShopOrderAddress.zip.placeholder')}
								value={data.zip}
							/>
						</Grid>
						<Grid item md={4}>
							<CardField disabled type={'text'} name={FieldName.State}
								label={t('component.CardShopOrderAddress.state.label')} placeholder={t('component.CardShopOrderAddress.state.placeholder')}
								value={data.state}
							/>
						</Grid>
						<Grid item md={4}>
							<CardField disabled type={'text'} name={FieldName.Country}
								label={t('component.CardShopOrderAddress.country.label')} placeholder={t('component.CardShopOrderAddress.country.placeholder')}
								value={data.country}
							/>
						</Grid>
					</Grid>

				</CardContent>
			</Card>
		</>
	)
}

export default Shipping