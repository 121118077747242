import { Color } from 'theme/style';

const style = {
  root: {
    color: Color.Text,
    minWidth: '20px'
  }
}

export default style
