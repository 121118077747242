import React from 'react'
import { Provider as StoreProvider } from 'react-redux'
import { configureStore } from 'app/session/store'
import { Router } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import { routes } from 'app/routes'
import { createBrowserHistory } from 'history'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import 'assets/stylesheets/main.scss'
import { ThemeProvider } from '@material-ui/styles'
import theme from 'theme/config'
import { HelmetProvider } from 'react-helmet-async'
import { LocalizationProvider, ToastProvider, ScrollReset } from 'components'
import { config } from 'app/config'

if (process.env.NODE_ENV !== 'development') {
  function empty() { }
  console.log = empty
  console.warn = empty
  console.error = empty
}

const history = createBrowserHistory()
const store = configureStore()

const App = () => {
  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
            <LocalizationProvider defaultLanguage={config.app.defaultLanguageCode}>
              <HelmetProvider>
                <ScrollReset />
                <ToastProvider />
                {renderRoutes(routes)}
              </HelmetProvider>
            </LocalizationProvider>
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StoreProvider>
  )
}

export default App
