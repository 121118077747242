import { lazy } from 'react'
import { RouteConfig } from 'react-router-config'

const routes: RouteConfig[] = [
	{ path: '/insurance/clients/:tab?', exact: true, component: lazy(() => import('views/screens/insurance/Clients')), },
	{ path: '/insurance/clients/client/:id/:tab?', exact: true, component: lazy(() => import('views/screens/insurance/ClientDetails')) },

	{ path: '/insurance/policies/:tab?', exact: true, component: lazy(() => import('views/screens/insurance/Policies')), },
	{ path: '/insurance/policies/policy/:id/:tab?', exact: true, component: lazy(() => import('views/screens/insurance/PolicyDetails')) },

	{ path: '/insurance/claims/:tab?', exact: true, component: lazy(() => import('views/screens/insurance/Claims')), },
	{ path: '/insurance/claims/claim/:id/:tab?', exact: true, component: lazy(() => import('views/screens/insurance/ClaimDetails')) },
]

export default routes