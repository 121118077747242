import { makeStyles, Tooltip, TooltipProps } from '@material-ui/core'
import { Value, Color, FontWeight } from 'theme/style'

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: Color.White,
  },
  tooltip: {
    backgroundColor: Color.White,
    fontSize: '1.2rem',
    padding: theme.spacing(1.5),
    color: Color.TextPrimary,
    borderRadius: Value.BorderRadius_Tooltip,
    boxShadow: '12px 12px 18px 0 rgba(0,0,0,0.08)'
  },
  text: {
    '& b': {
      fontWeight: FontWeight.SemiBold,
      letterSpacing: '0.06px',
    },
    '& mark': {
      color: Color.Error,
      backgroundColor: 'transparent'
    }
  }
}))

interface CustomTooltipType extends TooltipProps {
  htmlTitle?: string
}

const CustomTooltip = (props: CustomTooltipType) => {
  const classes = useStyles()

  function renderTitle(): React.ReactFragment {
    if (props.htmlTitle != null) return <span className={classes.text} dangerouslySetInnerHTML={{ __html: props.htmlTitle as string }}></span>
    return <span className={classes.text}>{props.title}</span>
  }
  return (
    <Tooltip {...props} arrow classes={classes} title={renderTitle()} />
  )
}

export default CustomTooltip
