import { Input, InputBaseProps, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Color, Value } from 'theme/style'
import { useLocalization } from 'components/methods'
import { Tooltip } from 'components'
import EditIcon from '@material-ui/icons/Edit'
import ClearIcon from '@material-ui/icons/Clear'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'flex',
    flex: 1,
    height: '45px',
    backgroundColor: Color.Background,
    borderRadius: Value.BorderRadius_Field,
    padding: theme.spacing(0, 1.5),
    paddingRight: 0,
    '&> div': {
      textAlign: 'left',
      flex: 1,
      '& input': {
        paddingLeft: 0,
      }
    },
  },
  action: {
    minWidth: '45px',
    width: '45px',
    flex: '0 0 45px',
    height: '45px',
    '&:hover': {
      backgroundColor: Color.Background,
    },
    '& svg': {
      fontSize: '20px',
      color: Color.TextSecondary
    }
  }
}))

interface ButtonFieldProps extends InputBaseProps {
  enableEdit?: boolean
  enableUpload?: boolean
  enableClear?: boolean
  onClick?: () => void
  onRemove?: () => void
  onUpdate?: (name: string, value: any) => void
  onConfirm?: (name: string, value: any) => void
}
const ButtonField = ({ ...props }: ButtonFieldProps) => {
  const classes = useStyles()
  const { t } = useLocalization()



  //CLICK

  function click() {
    if (props.onClick != null) props.onClick()
  }

  function disabledClick() {
  }

  function remove() {
    if (props.onRemove != null) props.onRemove()
  }



  //RENDER

  return (
    <div className={classes.input}>
      <Input {...props} disabled onClick={disabledClick}></Input>
      {props.enableEdit === true && <Tooltip title={t('common.field.edit')} placement="top"><Button className={classes.action} onClick={click}><EditIcon /></Button></Tooltip>}
      {props.enableUpload === true && <Tooltip title={t('common.field.upload')} placement="top"><Button className={classes.action} onClick={click}><CloudUploadIcon /></Button></Tooltip>}
      {props.enableClear === true && <Tooltip title={t('common.field.clear')} placement="top"><Button className={classes.action} onClick={remove}><ClearIcon /></Button></Tooltip>}
    </div>
  )
}

export default ButtonField