export enum TableResultsOption {
	_10 = 10,
	_25 = 25,
	_50 = 50,
	_100 = 100,
	_250 = 250,
	_500 = 500,
	_1000 = 1000,
	_2500 = 2500,
	_5000 = 5000,
	_10000 = 10000,
}