import React from 'react'
import { CardPageTags, PageEntityDetailsRefreshableProps } from 'components'
import { Page } from 'app/entities/types'

interface PageStatusProps extends PageEntityDetailsRefreshableProps {
	object: Page
	pageId: number
}

const PageStatus = ({ ...props }: PageStatusProps) => {
	return (
		<CardPageTags object={props.object} pageId={props.pageId} refreshObject={props.refreshObject} />
	)
}

export default PageStatus