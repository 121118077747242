import { Input, InputBaseProps, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { Color, FontWeight } from 'theme/style'

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: 'flex',
  },
  input: {
    flex: 1,
    backgroundColor: Color.Background,
    padding: theme.spacing(0, 1.5),
    '& input, & textarea': {
      padding: theme.spacing(0),
      textAlign: 'left',
      height: '100%',
    },
    '& textarea': {
      padding: theme.spacing(1, 0),
      height: '100px !important',
    },
  },
  suffixText: {
    color: Color.Secondary,
    fontWeight: FontWeight.Medium,
    letterSpacing: -0.5,
    fontSize: '1.2rem',
    marginLeft: theme.spacing(1)
  },
  prefixText: {
    color: Color.Secondary,
    fontWeight: FontWeight.Medium,
    letterSpacing: -0.5,
    fontSize: '1.2rem',
    marginRight: theme.spacing(1)
  },
}))

interface CardInputFieldProps extends InputBaseProps {
  className?: string
  onUpdate?: (name: string, value: any) => void
  onConfirm?: (name: string, value: any) => void
  enableEnterConfirm?: boolean
}
const TextField = ({ ...props }: CardInputFieldProps) => {
  const classes = useStyles()



  //FIELD CHANGES

  function updateField(event: any) {
    const target = event.target as HTMLInputElement
    const name = target.name as string
    const value = target.value

    if (props.onUpdate == null) return
    props.onUpdate(name, value)
  }

  function confirmField(event: any) {
    const target = event.target as HTMLInputElement
    const name = target.name as string
    const value = target.value

    if (props.onConfirm == null) return
    props.onConfirm(name, value)
  }

  function keyPressed(e: any) {
    if (props.enableEnterConfirm !== true) return
    if (e.key === 'Enter') {
      e.preventDefault()
      confirmField(e)
    }
  }



  //RENDER

  return (
    <div className={classes.inputContainer}>
      <Input {...props} className={clsx(classes.input, props.className)} onChange={updateField}
        onKeyPress={(ev) => { keyPressed(ev) }}
      ></Input>
    </div>
  )
}

export default TextField



type TextFieldAdornmentProps = {
  text: string
}
export const TextFieldAdornmentSuffix = ({ text }: TextFieldAdornmentProps) => {
  const classes = useStyles()
  return (
    <Typography className={classes.suffixText}>{text}</Typography>
  )
}

export const TextFieldAdornmentPrefix = ({ text }: TextFieldAdornmentProps) => {
  const classes = useStyles()
  return (
    <Typography className={classes.prefixText}>{text}</Typography>
  )
}