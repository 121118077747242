import _ from 'lodash'
import { FieldsListErrors, FieldError } from 'components'

// Check if a button is valid and should not be disabled.
export function isValid(conditions: FieldsListErrors, names?: string[]): boolean {
  var valid = true
  let list = Object.keys(conditions)
  if (names != null) list = names

  list.forEach(n => {
    const c = conditions[n]
    const result = _.find(c, function (i: FieldError) {
      return i.error === true
    })
    if (valid === true && result) valid = false
  })

  return valid
}