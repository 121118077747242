import palette from 'theme/config/palette';

const style = {
  root: {
    backgroundColor: palette.white,
    padingTop: 0,
    borderBottom: `2px solid ${palette.divider}`,
  }
}

export default style
