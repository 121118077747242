import spacing from 'theme/config/spacing';
import { Color, FontWeight } from 'theme/style';
import typography from 'theme/config/typography'
import { lighten } from '@material-ui/core/styles'

const style = {
  root: {
    ...typography.h6,
    height: '34px',
    minHeight: '34px',
    minWidth: '0 !important',
    marginRight: spacing(2),
    padding: spacing(1),
    position: 'relative',
    fontWeight: FontWeight.SemiBold,
    borderRadius: '17px',
    transition: 'all 0.2s',
  },
  'textColorInherit': {
    color: Color.TextSecondary,
    opacity: 1,
    '&:hover': {
      backgroundColor: lighten(Color.TextDisabled, 0.4),
    },
    '&$selected': {
      color: Color.White,
      backgroundColor: Color.Secondary,
      boxShadow: '6px 6px 16px 0 rgba(0,0,0,0.12)',
      '&:hover': {
        backgroundColor: Color.Secondary,
      },
    }
  }
}

export default style
