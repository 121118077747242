
//Defines all the Field Group Types available in the app.
//IMPORTANT: Must be mapped to each specific field group type keyword to work properly.
export enum PageTypeValue {
  Default = 'default',
  ThankYou = 'thank-you',
  Policy = 'policy',
  Error = 'error',

  Article = 'article',
  Product = 'product',
}