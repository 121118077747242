import React from 'react';

const useDidMountEffect = (func: React.EffectCallback, deps: React.DependencyList | undefined) => {
	const didMount = React.useRef(false);

	React.useEffect(() => {
		if (didMount.current) func();
		else didMount.current = true;
	}, deps);
}

export default useDidMountEffect