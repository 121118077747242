import { Grid } from '@material-ui/core';
import { Language, Page } from 'app/entities/types';
import { CardMetatags, PageEntityDetailsRefreshableProps } from 'components';
import React from 'react';

interface PageEntityTabSEOProps extends PageEntityDetailsRefreshableProps {
  object: Page
  language: Language
  pageId: number
}
const PageEntityTabSEO = ({ ...props }: PageEntityTabSEOProps) => {
  return (
    <Grid container>
      <Grid item xs={12} xl={6}>
        <CardMetatags pageId={props.pageId} language={props.language} refreshObject={props.refreshObject} />
      </Grid>
    </Grid>
  )
}

export default PageEntityTabSEO
